import React from 'react';
import './Footer.scss';
import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';


const Footer = ({ data }) => {
  return (
    <div className='footer'>
      <div className='footer_main'>
        <div className='left'>
          <img className='footer_logo' src={data.logo}/>
          <div className='footer_info'>
            <p>
              INVERSIONES ANTARES-TECH SAS
              <br></br>
              NIT 901.453.600-1
              <br></br>
              info@creditechantares.com
              <br></br>
              3106239462
              <br></br>
              Calle 93 # 15-51 oficina 104 - Bogotá
              <br></br>
              Copyright - 2024
            </p>
          </div>
        </div>
        <a target="_blank" href="http://www.sic.gov.co/" className='footer_logo-sic'>
          <img src={data.logoSic}/>
        </a>
      </div>
      <p className='footer_disclaimer'>
        Se prohibe la reproducción total o parcial de cualquiera de los contenidos que aquí aparezca, así como su traducción a cualquier idioma sin autorización escrita por su titular.
      </p>
      <Link to="/terminos" className='footer_link'>
        TÉRMINOS Y CONDICIONES DE LA PÁGINA WEB
      </Link>
      <Link to="/politicas" className='footer_link'>
        POLÍTICA PARA EL TRATAMIENTO DE LA INFORMACIÓN Y LOS DATOS PERSONALES
      </Link>
    </div>
  );
};

export default Footer;
