import React from 'react';
import logo from './images/antareslogo2x.png';
import './Static.scss';
import SingleHeader from './Components/SingleHeader/SingleHeader';
import HomeWidget from './Components/HomeWidget/HomeWidget';
import logoHome from './images/home-widget.png';

const Terms = () => {
  return (
    <div>
      <SingleHeader data={{
        logoUrl: logo
      }}
      />
      <HomeWidget
        logoHome={logoHome}
      />
      <div className="terms">
        <p class="c13"><span class="c10">T&Eacute;RMINOS</span><span class="c2">&nbsp;Y CONDICIONES DE LA P&Aacute;GINA WEB
          &ldquo;WWW. CREDITECHANTARES.COM&rdquo; PARA LA SOLICITUD DE CR&Eacute;DITOS PERSONALES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">Versi&oacute;n vigente desde el 1 de abril de 2024. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El sitio web </span><span class="c2">ANTECH </span><span
          class="c0">(https://creditechantares.com) es propiedad </span><span class="c2">INVERSIONES ANTARES-TECH
            SAS.</span><span class="c0">, distinguida con el NIT. 901.774.639-5, en adelante </span><span
              class="c2">ANTECH</span><span class="c0">; sociedad comercial colombiana, legalmente constituida, con
                domicilio en la ciudad de Bogot&aacute; D.C. &ndash; Colombia, la cual presta los servicios a trav&eacute;s
                de su sitio web. Se le recomienda a todos los clientes leer detalladamente la informaci&oacute;n que se
                suministra antes de hacer uso de del sitio web, en conjunto con la pol&iacute;tica de tratamiento de datos
                personales. Tenga en cuenta que la aceptaci&oacute;n de la misma, significa que usted como consumidor
                est&aacute; de acuerdo con los t&eacute;rminos y condiciones de los servicios ofrecidos a trav&eacute;s de
                la p&aacute;gina web, relacionados con:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <ul class="c11 lst-kix_list_1-0 start">
          <li class="c1 c9 li-bullet-0"><span class="c0">Adquisici&oacute;n de cr&eacute;ditos personales. </span></li>
          <li class="c1 c9 li-bullet-0"><span class="c0">Adquisici&oacute;n de cr&eacute;ditos de baja monta. </span></li>
          <li class="c1 c9 li-bullet-0"><span class="c0">Financiaci&oacute;n. </span></li>
        </ul>
        <p class="c1 c3"><span class="c0"></span></p>
        <p class="c1"><span class="c0">En caso contrario, el Usuario/Cliente deber&aacute; abstenerse de acceder la
          p&aacute;gina web y la presentaci&oacute;n de una solicitud ya sea directa o indirectamente, y utilizar
          cualquier informaci&oacute;n o servicio provisto por la misma.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Mediante el acceso y uso de los Servicios, usted declara expresamente su
          intenci&oacute;n y aceptaci&oacute;n de vincularse jur&iacute;dicamente por estos T&eacute;rminos y
          Condiciones, que establecen una relaci&oacute;n contractual entre el usuario del portal web y ANTECH. Si
          usted no acepta dichos T&eacute;rminos y Condiciones, no podr&aacute; acceder o usar los Servicios. Estos
          T&eacute;rminos y Condiciones sustituyen expresamente los acuerdos o compromisos previos con usted. ANTECH
          podr&aacute; poner fin de inmediato a estos T&eacute;rminos y Condiciones o cualquiera de los Servicios
          respecto de usted o, en general, dejar de ofrecer o denegar el acceso a los Servicios o cualquier parte de
          ellos, en cualquier momento y por cualquier motivo.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c8">ANTECH podr&aacute; modificar los T&eacute;rminos y Condiciones relativos a los
          Servicios cuando lo considere oportuno. Las modificaciones se har&aacute;n efectivas despu&eacute;s de la
          publicaci&oacute;n por parte de ANTECH de los T&eacute;rminos y Condiciones actualizados en esta
          ubicaci&oacute;n o las pol&iacute;ticas modificadas o condiciones suplementarias sobre el servicio
          respectivo. Su acceso o uso continuado de los Servicios despu&eacute;s de dicha publicaci&oacute;n
          constituye su consentimiento a vincularse por los T&eacute;rminos y Condiciones y sus modificaciones. La
          recopilaci&oacute;n y el uso que hacemos de la informaci&oacute;n personal en relaci&oacute;n con los
          Servicios es conforme se dispone en la pol&iacute;tica de tratamiento de datos personales disponible en
        </span><span class="c8 c12"><a target="_blank" class="c6"
          href="https://www.google.com/url?q=https://creditechantares.com&amp;sa=D&amp;source=editors&amp;ust=1713148146821150&amp;usg=AOvVaw1GQ--Yxk7xOE8wf9IJCQUa">https://creditechantares.com</a></span><span
            class="c0">&nbsp;</span></p>
        <p class="c1 c4"><span class="c2"></span></p>
        <p class="c1"><span class="c2">1. DEFINICIONES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Los t&eacute;rminos cuya primera letra figura en may&uacute;scula (salvo cuando se
          deba exclusivamente a que inician una oraci&oacute;n o se trate de un nombre propio), tienen el significado
          que se les asigna a continuaci&oacute;n, o el que se les asigne expresamente en estos T&eacute;rminos y
          Condiciones o en la Pol&iacute;tica de Privacidad:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Pagina Web: Espacio por medio de la cual las personas podr&aacute;n solicitar
          pr&eacute;stamos de bajo monto online de libre destinaci&oacute;n y a corto plazo. La plataforma
          podr&aacute; brindar un pr&eacute;stamo y un usuario para identificar los plazos y montos que deber&aacute;
          pagar al momento de su consulta. Del mismo modo, tendr&aacute; el detalle de las cuotas de forma tal que se
          podr&aacute; identificar la cantidad de cuotas, el valor y la fecha del primer pago luego de otorgado el
          pr&eacute;stamo.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Usuario/Cliente: Toda persona natural mayor de dieciocho (18) a&ntilde;os que, como
          destinatario final, use la p&aacute;gina web con el fin de obtener los servicios de pr&eacute;stamos online
          a trav&eacute;s de la plataforma digital de libre destinaci&oacute;n, bajo monto y corto plazo.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Proveedores: Toda persona natural o jur&iacute;dica que provea de alg&uacute;n
          servicio o bien directamente a ANTECH para el desarrollo de las actividades desarrolladas por la
          plataforma.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Navegadores de la p&aacute;gina web: Todas aquellas personas que sean consideradas
          como Usuarios, Proveedores, Clientes, Comerciantes y/o colaboradores de contenido</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Formulario de solicitud: Espacio por medio del cual el usuario/cliente suministra
          informaci&oacute;n para la presentaci&oacute;n de su solicitud de cr&eacute;dito. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Pr&eacute;stamo: Monto de dinero dado en calidad de mutuo mercantil al
          Usuario/Cliente, en virtud de una solicitud formalmente realizada por medio de la Pagina Web, con la
          finalidad de ser restituida con posterioridad una vez se cumpla el plazo establecido en el contrato de
          mutuo. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Tasas e intereses: R&eacute;ditos que generan los pr&eacute;stamos que han sido
          otorgados a los Usuarios en virtud de la plataforma, mismos que surgen como consecuencia del otorgamiento de
          un monto de dinero con finalidad de ser restituida en un periodo de tiempo determinado.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Garant&iacute;as: Todas aquellas figuras jur&iacute;dicas que sean celebradas entre
          el Usuario/Cliente y ANTECH con la finalidad de garantizar el pr&eacute;stamo que ha sido otorgado por medio
          de la plataforma, mismos que pueden constituirse por medio de t&iacute;tulos valores o cualquier otro
          documento legal.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Calificaci&oacute;n crediticia: An&aacute;lisis e investigaci&oacute;n realizada por
          los Proveedores o por ANTECH directamente para identificar la viabilidad econ&oacute;mica de la
          celebraci&oacute;n del presente vinculo jur&iacute;dico.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">2. ACEPTACI&Oacute;N DE LOS T&Eacute;RMINOS Y CONDICIONES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Mediante la aceptaci&oacute;n de los T&eacute;rminos y Condiciones establecidos en
          esta p&aacute;gina web el Usuario/Cliente acepta las condiciones aqu&iacute; contenidas, as&iacute; como la
          Pol&iacute;tica de Tratamiento de datos Personales de ANTECH. As&iacute; mismo, se entiende que acepta las
          dem&aacute;s reglas de operaci&oacute;n, pol&iacute;ticas, procedimientos y dem&aacute;s actualizaciones
          desde la entrada de operaci&oacute;n de la sociedad a la fecha, que puedan ser publicados por ANTECH en la
          p&aacute;gina web, cada uno de los cuales se incorpora por referencia. Sin perjuicio de lo anterior, algunos
          servicios que se ofrecen en la Plataforma pueden estar sujetos a T&eacute;rminos y Condiciones adicionales,
          en este caso, el uso que el Usuario haga de dichos servicios estar&aacute; sujeto a la aceptaci&oacute;n
          espec&iacute;fica por parte del Cliente. En caso de que el Usuario no los acepte, deber&aacute; abstenerse
          de utilizar en cualquier forma dichos servicios.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">3. CAPACIDAD</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">De conformidad con el ordenamiento jur&iacute;dico colombiano el Usuario/Cliente que
          acepta estos T&eacute;rminos y Condiciones debe ser legalmente apto y contar con las autorizaciones
          pertinentes para la vinculaci&oacute;n de la persona a la que representa. De acuerdo con lo anterior, el
          Usuario/Cliente garantiza y ANTECH entiende que aquel que acepta los presentes T&eacute;rminos y Condiciones
          conoce previamente si est&aacute; o no en capacidad de celebrar contratos a nombre de la persona que se
          determine como Usuario.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">4. ALCANCE DEL SERVICIO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH es una p&aacute;gina web, por medio de la cual se genera el recibo de
          solicitudes de cr&eacute;dito o pr&eacute;stamos personales, por medio de las cuales los usuarios/clientes,
          pueden presentar una solicitud de cr&eacute;dito. La p&aacute;gina web permite la presentaci&oacute;n de
          solicitudes de cr&eacute;dito desde el mill&oacute;n de pesos colombianos hasta seis millones de pesos
          colombianos. Lo anterior sin perjuicio que ANTECH genere de forma unilateral y sin previo avos cambios o
          modificaci&oacute;n sobre los valores o cupos a los que podr&aacute;n acceder los usuarios/clientes. </span>
        </p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Para el desarrollo y uso de la p&aacute;gina web los usuarios/clientes deber&aacute;n
          cumplir con los requisitos que se encuentran establecidos los presentes t&eacute;rminos y condiciones,
          mismos que aquel acepta libre y espont&aacute;neamente, entendiendo las consecuencias jur&iacute;dicas y
          econ&oacute;micas que pueden producirse con la adquisici&oacute;n de los pr&eacute;stamos otorgados por
          ANTECH. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">De esa forma, ANTECH se presenta como un servicio y producto que otorga soluciones
          econ&oacute;micas a sus usuarios a trav&eacute;s de principios de flexibilidad, rapidez y con la firma de
          documentos f&iacute;sicos necesarios (pagar&eacute;, carta de autorizaci&oacute;n de libranza, entre otros).
          As&iacute; mismo, ANTECH otorgar&aacute; los respectivos pr&eacute;stamos a los Usuarios/Clientes por medio
          de cuotas o plazos determinados, que ser&aacute;n previamente aceptados por aquellos. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Del mismo modo, ANTECH podr&aacute; solicitar de forma previa o en desarrollo y
          ejecuci&oacute;n del presente acuerdo de voluntades, informaci&oacute;n personal y sensible para evaluar las
          posibilidades y factibilidad al momento de otorgar pr&eacute;stamos o para identificar la probabilidad de
          cumplimiento en el desarrollo de las obligaciones que se encuentran a cargo del Usuario/Cliente. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Las solicitudes de cr&eacute;dito realizadas por medio de la p&aacute;gina
          podr&aacute;n ser o no otorgadas a discreci&oacute;n y unilateralmente por parte de ANTECH. La
          determinaci&oacute;n de viabilidad del pr&eacute;stamo se producir&aacute; por medio del an&aacute;lisis de
          la calificaci&oacute;n crediticia y la transferencia o transmisi&oacute;n de los datos personales o
          sensibles de los Usuarios/Clientes a entidades crediticias certificadas para evaluar la capacidad
          econ&oacute;mica y de endeudamiento de los Usuarios. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Sin perjuicio de lo anterior, ANTECH se reserva el derecho de entregar o no
          informaci&oacute;n relacionada con la negativa en el otorgamiento de los pr&eacute;stamos, de modo tal que
          esto no podr&aacute; constituir violaci&oacute;n alguna de sus obligaciones. Unilateralmente, ANTECH
          podr&aacute; renunciar al derecho de reservarse dicha informaci&oacute;n siempre y cuando la solicitud de la
          negativa sea producida por la persona titular de la informaci&oacute;n o de una autoridad judicial por medio
          de providencia judicial en firme.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH realizar&aacute; el desembolso del cupo de cr&eacute;dito una vez se haya
          realizado las validaciones y verificaciones para la aprobaci&oacute;n definitiva del pr&eacute;stamo,
          as&iacute; como, la firma de los documentos requeridos para formalizar el proceso del cr&eacute;dito, los
          cuales, de ser requerido podr&aacute;n firmarse de manera digital o f&iacute;sica a discreci&oacute;n de
          ANTECH. El pr&eacute;stamo se desembolsar&aacute; en los tiempos permitidos por los ciclos ACH, m&aacute;s
          los tiempos de acreditaci&oacute;n del banco receptor en la cuenta bancaria que para tal fin haya
          determinado el Usuario/Cliente y que se debe encontrar a su nombre. No podr&aacute; realizarse desembolso
          alguno de dinero a cuentas bancarias que no se encuentren a titularidad del solicitante- </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH podr&aacute; solicitar, para la aprobaci&oacute;n del respectivo
          pr&eacute;stamo, la suscripci&oacute;n un pagar&eacute; desmaterializado o f&iacute;sico, carta de
          autorizaci&oacute;n de descuento por libranza, cesi&oacute;n de derechos econ&oacute;micos, prenda,
          garant&iacute;a mobiliaria u otro elemento requerido para la gesti&oacute;n de garant&iacute;as, que se
          concreta con el usuario seg&uacute;n las necesidades al momento de la celebraci&oacute;n del
          pr&eacute;stamo. Esto ser&aacute; un requisito sine qua-non para el perfeccionamiento del presente
          instrumento jur&iacute;dico. El incumplimiento de las obligaciones por parte del Usuario podr&aacute;
          hacerse exigible por v&iacute;a de proceso ejecutivo la totalidad del valor que ha sido otorgado por medio
          del pr&eacute;stamo, los intereses y la mora que se produzca como consecuencia del no pago de las cuotas o
          plazos fijados por medio de la solicitud presentada en p&aacute;gina web por parte del usuario/cliente.
        </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c10">4.1. ALCANCE DEL SERVICIO EN RELACI&Oacute;N CON ANTECH</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH se compromete a prestar los servicios antes descritos, a partir de la
          presentaci&oacute;n por medio de su p&aacute;gina web de las solicitudes de cr&eacute;dito por parte de los
          usuarios/clientes. ANTECH celebrar&aacute; con aquellos un contrato de mutuo mercantil sobre los valores que
          sean solicitados por medio de la p&aacute;gina y que resulten autorizados de conformidad con el estudio que
          para tal fin se realice. El perfeccionamiento del contrato se dar&aacute; una vez el mismo haya sido
          suscrito entre ANTECH y el cliente/usuario, as&iacute; como, se haya realizado la correspondiente
          transferencia bancaria en favor de aquel. ANTECH una vez perfeccionado el contrato asume la
          obligaci&oacute;n de desembolsar el dinero en favor del cliente/usuario en las condiciones se&ntilde;aladas
          y aceptadas, quien deber&aacute; realizar la restituci&oacute;n de este en los t&eacute;rminos y forma
          indicados en la solicitud y el contrato (plazo e intereses). </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">4.2. ALCANCE DEL SERVICIO EN RELACI&Oacute;N CON LOS USUARIOS/CLIENTES:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Los Usuarios/Clientes podr&aacute;n acceder a la p&aacute;gina web en cualquier
          momento y solicitar pr&eacute;stamos de corta duraci&oacute;n y en los t&eacute;rminos que se evidencian en
          el presente instrumento jur&iacute;dico. En esos t&eacute;rminos, para la solicitud del respectivo
          pr&eacute;stamo, el Usuario debi&oacute; aceptar el presente instrumento jur&iacute;dico, as&iacute; como la
          Pol&iacute;tica de Tratamiento de Datos Personales, misma que autoriza a ANTECH a utilizar
          informaci&oacute;n personal o sensible para evidenciar la viabilidad econ&oacute;mica y jur&iacute;dica del
          contrato. La relaci&oacute;n jur&iacute;dica del Usuario respecto de ANTECH empezar&aacute; desde el momento
          exacto en el que solicite el pr&eacute;stamo por medio de la p&aacute;gina web, momento mismo en el que
          adquiere la obligaci&oacute;n de realizar cualquier actividad bajo el principio de buena fe mercantil, desde
          el momento precontractual hasta el postcontractual.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">En esos t&eacute;rminos, una vez aprobado el pr&eacute;stamo y efectuado el
          desembolso de la suma de dinero solicitada por medio de la p&aacute;gina web, el Usuario/Cliente se
          compromete a restituir el dinero dado en mutuo con los intereses que estos hayan generado al momento del
          pago teniendo en cuenta la informaci&oacute;n que fue suministrada y aceptada previamente por el
          Usuario/Cliente durante el tr&aacute;mite de la solicitud del cr&eacute;dito. Del mismo modo, el
          Usuario/Cliente se compromete a suministrar toda la informaci&oacute;n que sea necesaria para desarrollar la
          respectiva evaluaci&oacute;n de viabilidad de los respectivos pr&eacute;stamos; toda informaci&oacute;n
          suministrada por el Usuario/Cliente deber&aacute; ser veraz y autentica, de forma tal que, el incumplimiento
          de esta obligaci&oacute;n podr&iacute;a generar la exigibilidad de la cl&aacute;usula penal por parte de
          ANTECH. Al momento de la ejecuci&oacute;n del presente contrato, el Usuario/Cliente se compromete a
          suscribir un pagar&eacute;, as&iacute; como, las garant&iacute;as que correspondan, mismo que servir&aacute;
          como garant&iacute;a del negocio subyacente. La duraci&oacute;n del contrato se entender&aacute; celebrada
          por el t&eacute;rmino en que se solicite el pr&eacute;stamo y mismo que estar&aacute; definido en el
          contrato y que ser&aacute; resultado de la informaci&oacute;n que se suministre en el Usuario/Cliente al
          momento de la presentaci&oacute;n de la solicitud y el estudio o an&aacute;lisis que realice ANTECH al
          momento de aprobar el cr&eacute;dito. &nbsp;</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5. DISPOSICIONES GENERALES </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.1. SERVICIOS PRESTADOS</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Por medio de la p&aacute;gina web ANTECH recibir&aacute; las solicitudes de
          pr&eacute;stamos de corta duraci&oacute;n a los Usuarios/Clientes, mismos que ser&aacute;n efectuados
          mediante la celebraci&oacute;n de un contrato de mutuo mercantil, a trav&eacute;s del cual, ANTECH
          efectuar&aacute; la entrega material de consignaci&oacute;n de los dineros solicitados en el t&eacute;rmino
          que fue previsto en las condiciones pactadas entre las partes, teniendo en cuenta el inter&eacute;s que fue
          fijado de forma previa, condiciones que han sido aceptadas por el Usuario/Cliente durante el desarrollo del
          tr&aacute;mite de la solicitud. La solicitud del pr&eacute;stamo se entender&aacute; como aceptaci&oacute;n
          completa y sin excepci&oacute;n de los presentes t&eacute;rminos y condiciones. El no pago de alguno de los
          de restituci&oacute;n o cuotas definidas en la solicitud y el contrato constituir&aacute; incumplimiento de
          los T&eacute;rminos y Condiciones, dando la posibilidad de hacer exigible la cl&aacute;usula penal, la
          solicitud por v&iacute;a judicial y la exigibilidad de las sumas de dinero por medio del pagar&eacute; y
          dem&aacute;s garant&iacute;as, mismo que debi&oacute; haber sido suscrito de forma previa al desembolso,
          como requisito para el perfeccionamiento del contrato subyacente. Las condiciones, requisitos y elementos
          del contrato de mutuo mercantil se reglamentar&aacute;n por medio de los presentes T&eacute;rminos y
          Condiciones que podr&aacute;n ser actualizados por parte de ANTECH siempre y cuando no vulneren derechos
          fundamentales del Usuario/Cliente. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.2. INSTRUMENTACI&Oacute;N DEL PAGAR&Eacute; Y OTRAS GARANT&Iacute;AS</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El Usuario/Cliente reconoce y autoriza que la instrumentaci&oacute;n del
          pagar&eacute; y contrato de cr&eacute;dito y otras garant&iacute;as adicionales se llevar&aacute; a cabo a
          trav&eacute;s del mecanismo de firma electr&oacute;nica aceptando a su vez los t&eacute;rminos, condiciones
          y autorizaciones para la adquisici&oacute;n del pr&eacute;stamo requerido, o en su defecto por medio de la
          suscripci&oacute;n de los mismos de forma f&iacute;sica con nota de presentaci&oacute;n personal. </span>
        </p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.3. TIEMPOS DE DESEMBOLSO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH realizar&aacute; el desembolso del pr&eacute;stamo una vez se haya realizado
          las validaciones y verificaciones para la aprobaci&oacute;n definitiva del pr&eacute;stamo. El
          pr&eacute;stamo se desembolsar&aacute; en los tiempos permitidos por los ciclos ACH, m&aacute;s los tiempos
          de acreditaci&oacute;n del banco receptor en la cuenta bancaria que para tal fin haya determinado el Usuario
          y que se debe encontrar a su nombre. El desembolso puede demorar entre 2 a 24 horas h&aacute;biles
          aproximadamente: las solicitudes iniciadas el fin de semana o en d&iacute;a no h&aacute;bil, la misma se
          gestionan a partir del primer d&iacute;a h&aacute;bil siguiente. Si la cuenta bancaria no es v&aacute;lida,
          el banco nos avisar&aacute; dentro de las 72 horas. No podr&aacute; realizarse desembolso alguno de dinero a
          cuentas bancarias que no se encuentren a nombre del titular que efectivamente solicite el pr&eacute;stamo
          respectivo. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.4. CONDICIONES TRANSPARENTES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Los costos asociados y no asociados al cr&eacute;dito se calculan seg&uacute;n el
          nivel de riesgo de EL USUARIO/CLIENTE que se determina de acuerdo con las pol&iacute;ticas de riesgo
          establecidas por ANTECH las cuales son el resultado de la consulta en las centrales de informaci&oacute;n
          financiera, m&aacute;s otras variables de aceptaci&oacute;n.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">PR&Eacute;STAMO DESEMBOLSADO: Monto de dinero aprobado.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">INTER&Eacute;S REMUNERATORIO (TEA): La tasa de inter&eacute;s efectiva anual
          legalmente constituida.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">EMISI&Oacute;N DE PAGAR&Eacute;: Emisi&oacute;n del t&iacute;tulo de cr&eacute;dito
          digital en el que el emisor se compromete a pagar una determinada suma de dinero en una fecha establecida,
          su firma digital y custodia en entidad especializada de administraci&oacute;n de t&iacute;tulos
          valores.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">VERIFICACI&Oacute;N Y PROTECCI&Oacute;N PERSONAL: Requisito &ldquo;Sine qua
          non&rdquo; para la celebraci&oacute;n del contrato donde se gestiona el cobro de las herramientas utilizadas
          para la protecci&oacute;n, administraci&oacute;n y tratamiento de la informaci&oacute;n personal
          suministrada por los usuarios. Este es un valor asociado por pago a terceros; se exceptuar&aacute;, de
          acuerdo con la clasi&#64257;caci&oacute;n de riesgo de&#64257;nida por ANTECH</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">CENTRALES DE RIESGO: Consulta ante centrales de riesgo crediticio de la
          informaci&oacute;n del usuario que permite generar un estudio a partir del an&aacute;lisis fragmentado de
          las variables que componen su score y sobre el cual se determinar&aacute; la concesi&oacute;n del
          pr&eacute;stamo. EL USUARIO/CLIENTE se except&uacute;a del cobro de este servicio, de acuerdo con la
          clasificaci&oacute;n de riesgo definida por ANTECH</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">OPERACI&Oacute;N ACH OTROS BANCOS: Asociado a los costos transaccionales derivados
          del m&eacute;todo de pago usado por ANTECH y/o EL USUARIO/CLIENTE, tanto en proceso de desembolso del
          pr&eacute;stamo y pago de deuda.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">VERIFICACI&Oacute;N TELEF&Oacute;NICA Y REFERENCIACI&Oacute;N: Validaci&oacute;n
          telef&oacute;nica a los n&uacute;meros registrados por EL USUARIO/CLIENTE y comprobaci&oacute;n de las
          referencias familiares y comerciales relacionadas.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">FACTURA ELECTR&Oacute;NICA: Emisi&oacute;n de la factura electr&oacute;nica asociada
          al cr&eacute;dito.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">IVA: Impuesto sobre el valor agregado de al&iacute;cuota de 19%.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.5. PAGO ANTICIPADO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El USUARIO/CLIENTE tendr&aacute; la opci&oacute;n de realizar el pago anticipado de
          su cr&eacute;dito rotativo de manera total o parcial en cualquier fecha y antes del d&iacute;a de
          vencimiento sin costo o sanci&oacute;n. S&iacute; El USUARIO realiza el pago total de la obligaci&oacute;n
          antes de la fecha de vencimiento se har&aacute; la correspondiente liquidaci&oacute;n de intereses al
          d&iacute;a del pago; los pagos parciales que realice EL USUARIO ser&aacute;n abonados como aporte a capital.
          En caso de pago total anticipado, se deber&aacute; comunicar previamente a las l&iacute;neas de
          atenci&oacute;n, para conocer el saldo total a la fecha en la que espera realizar el pago. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.6. MEDIOS DE PAGO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c8">Pago por transferencia Bancaria: &Uacute;nicamente realice transferencias bancarias a
          las siguientes cuentas bancarias; las cuentas relacionadas a continuaci&oacute;n son las &uacute;nicas
          o&#64257;ciales a nombre de </span><span class="c10">INVERSIONES ANTARES-TECH SAS.</span><span class="c0">,
            distinguida con el NIT. 901.774.639-5</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">CAJA SOCIAL: Cuenta Corriente 21004269668</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">EL USUARIO/CLIENTE al realizar el pago a trav&eacute;s de &ldquo;TRASFERENCIA
          BANCARIA&rdquo; deber&aacute; adjuntar el recibo de consignaci&oacute;n o comprobante al correo
          electr&oacute;nico: info@creditechantares.com</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">As&iacute; mismo, mediante la aceptaci&oacute;n de estos t&eacute;rminos y
          condiciones el Usuario es consciente y se compromete a pagar su obligaci&oacute;n a los medios de pago
          descritos anteriormente, dado que de realizar un pago a otro medio NO autorizado, ANTECH queda eximida de
          toda responsabilidad que pueda derivar, puesto que la sociedad no utiliza intermediarios, manteniendo una
          operaci&oacute;n transparente y directa mediante la solicitud directa por pate del titular o solicitante del
          cr&eacute;dito. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.7. DESISTIMIENTO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El USUARIO/CLIENTE, en ejercicio de su derecho al retracto podr&aacute; solicitar el
          desistimiento del pr&eacute;stamo requerido, hasta siete (7) d&iacute;as despu&eacute;s del desembolso del
          pr&eacute;stamo, por lo que El USUARIO/CLIENTE deber&aacute; realizar la devoluci&oacute;n de los recursos
          desembolsados asumiendo a su vez los cargos de adquisici&oacute;n del servicio, tales como;
          administraci&oacute;n, verificaci&oacute;n antifraude y biometr&iacute;a.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.8. REQUISITOS PARA REALIZAR EL REGISTRO EN LA PLATAFORMA</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Los Usuarios/Clientes que presenten solicitudes de cr&eacute;ditos por medio de la
          p&aacute;gina web deber&aacute;n contar, cuanto menos, con los siguientes requisitos, a saber:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Ser mayor de 18 a&ntilde;os.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Ser residente en la Rep&uacute;blica de Colombia.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Tener domicilio y residir en la Rep&uacute;blica de Colombia.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Tener capacidad legal para contratar.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Contar con un documento nacional de identidad v&aacute;lidamente emitido por la
          Rep&uacute;blica de Colombia y en condiciones de ser le&iacute;do electr&oacute;nicamente.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Cuenta de correo electr&oacute;nico propia y de uso personal.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Ser titular de una cuenta corriente o de ahorros o cuenta digital en una entidad
          financiera supervisada por la Superintendencia Financiera de Colombia. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.9. EXONERACI&Oacute;N DE RESPONSABILIDAD POR ATAQUES INFORM&Aacute;TICOS</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH no se har&aacute; responsable por ataques inform&aacute;ticos ex&oacute;genos
          de la debida diligencia en desarrollo de sus deberes de protecci&oacute;n a ataques
          inform&aacute;ticos.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.10. COSTOS POR CUENTA Y ORDEN DE TERCEROS EN LOS PR&Eacute;STAMOS</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH podr&aacute; realizar a los Usuarios/Clientes cobros por cuenta y orden de
          terceros por diferentes conceptos derivados de la identificaci&oacute;n, seguridad, validaci&oacute;n,
          verificaciones, fondo de garant&iacute;as/avalista, an&aacute;lisis crediticios, y cargos de recaudos en los
          pr&eacute;stamos que realiza, de ese modo, el Usuario conoce y acepta que, al momento de solicitar un
          pr&eacute;stamo de capital, ANTECH podr&iacute;a incorporar los cargos anteriormente descriptos. El valor de
          todos los cargos por cuenta y orden de terceros ser&aacute;n informados durante el desarrollo del estudio de
          la solicitud de pr&eacute;stamo, por lo que el Usuario/Cliente conocer&aacute; el valor de los cargos con
          anterioridad a la finalizaci&oacute;n del estudio pr&eacute;stamo y su aceptaci&oacute;n para desembolso; en
          esos t&eacute;rminos. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.11. GASTOS DE COBRANZA EN LOS PR&Eacute;STAMOS</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH realizar&aacute; a los Usuarios/Cliente un cobro por concepto de cobranza en
          los casos en los que EL USUARIO/CLIENTE se encuentre en mora respecto de sus obligaciones de pago en virtud
          del contrato celebrado por medio de los presentes T&eacute;rminos y Condiciones. Los gastos de cobranza
          equivalen a los porcentajes enunciados en el presente documento, mismos que se calculan en relaci&oacute;n
          con el total de la deuda y el incremento peri&oacute;dico de intereses de acuerdo con los d&iacute;as de
          mora de la cartera. Los gastos de cobranza son cobros que se realizan al USUARIO/CLIENTE correspondientes a
          conceptos como: personal de cobranza especializado, cargos de mensajer&iacute;as (SMS), env&iacute;o de
          mensajes de voz (IVR), llamados y mensajes de WhatsApp a los tel&eacute;fonos celulares registrados ante
          ANTECH con la finalidad de obtener el pago total de la obligaci&oacute;n; que no han sido cancelados por
          parte del USUARIO/CLIENTE en las fechas fijadas y convenidas de forma previa en el contrato. Los gastos de
          cobranza se realizar&aacute;n de conformidad con la siguiente formula y porcentajes:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Gastos de cobranza</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Formula: (&ldquo;Saldo capital&rdquo; X &ldquo;Porcentaje seg&uacute;n
          antig&uuml;edad de mora&rdquo;) + IVA</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">D&Iacute;AS DE MORA</span></p>
        <p class="c1 c4"><span class="c0"></span></p><a id="t.93c12c1d7e3efc76f7d2f1aba6de7517f2e83819"></a><a id="t.0"></a>
        <table class="c14">
          <tr class="c5">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">Rango de d&iacute;as en mora </span></p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">% de honorarios </span></p>
            </td>
          </tr>
          <tr class="c5">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">05 D&Iacute;AS A 30 D&Iacute;AS</span></p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">5%</span></p>
            </td>
          </tr>
          <tr class="c5">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">30 D&Iacute;AS A 60 D&Iacute;AS</span></p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">10%</span></p>
            </td>
          </tr>
          <tr class="c5">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">60 D&Iacute;AS A 120 D&Iacute;AS</span></p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">15%</span></p>
            </td>
          </tr>
          <tr class="c5">
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">MAYOR A 120 D&Iacute;AS</span></p>
            </td>
            <td class="c7" colspan="1" rowspan="1">
              <p class="c1"><span class="c0">25%</span></p>
            </td>
          </tr>
        </table>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.12. COBRO DE LA OBLIGACI&Oacute;N Y GASTOS DE COBRANZA</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El Usuario/Cliente al aceptar los presentes T&eacute;rminos y Condiciones, otorga
          autorizaci&oacute;n incondicional a ANTECH para efectuar la gesti&oacute;n de cobranza, as&iacute; como la
          realizaci&oacute;n de todas las actividades que resulten necesarias para garantizar el cobro total de la
          deuda; mediante los siguientes canales de comunicaci&oacute;n: (i) llamadas, (ii) mensajes de texto, (iii)
          mensajes, llamadas y videollamadas por WhatsApp, (iv) correo electr&oacute;nico, y; (v) env&iacute;o de
          mensajes de voz (IVR).</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">5.13. AUTORIZACI&Oacute;N DE CONSULTA, REPORTE Y TRANSMISI&Oacute;N DE
          INFORMACI&Oacute;N FINANCIERA Y DATOS PERSONALES. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El CLIENTE/USUARIO autoriza irrevocablemente que los datos de car&aacute;cter
          personal de su titularidad que se obtengan en virtud de la relaci&oacute;n comercial que mantenga o que haya
          mantenido con ANETECH, sean tratados en una o varias bases de datos administrada por ANETECH, quien
          ser&aacute; responsable de su administraci&oacute;n junto con los encargados que &eacute;ste designe, e
          implementar&aacute; las medidas de seguridad necesarias para la conservaci&oacute;n y protecci&oacute;n de
          estos. As&iacute; mismo, el CLIENTE/USUARIO declara que se le ha informado que sus datos personales han sido
          y ser&aacute;n recopilados, y utilizados para fines operativos, comerciales y de mercadeo, de
          prevenci&oacute;n de riesgo y estad&iacute;sticos, por ANTECH y/o por cualquiera de sus aliados comerciales,
          y en general para permitir el cumplimiento de las obligaciones que ANTECH haya adquirido en virtud de la
          presente relaci&oacute;n contractual o que le sean exigibles por disposiciones legales o reglamentarias. De
          igual forma CLIENTE/USUARIO autoriza que, para las finalidades descritas, ANTECH pueda suministrar su
          informaci&oacute;n a los siguientes tipos de personas: </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">a) Operadores de bases de datos, crediticio, financiero, comercial o de servicios o a
          otras entidades financieras nacionales o extranjeras.</span></p>
        <p class="c1"><span class="c0">b) Terceros que, en calidad de proveedores nacionales o extranjeros, en el
          pa&iacute;s o en el exterior, presten servicios tecnol&oacute;gicos, log&iacute;sticos, operativos, de
          seguridad o de apoyo, y en general que en ejercicio de su servicio deban tener acceso a la
          informaci&oacute;n por CLIENTE/USUARIO suministrada, </span></p>
        <p class="c1"><span class="c0">c) Las personas naturales o jur&iacute;dicas accionistas del ANTECH y a las
          sociedades controlantes, controladas, vinculadas, afiliadas o pertenecientes al mismo grupo
          empresarial,</span></p>
        <p class="c1"><span class="c0">d) Las autoridades nacionales o extranjeras que en ejercicio de sus competencias y
          con autorizaci&oacute;n legal lo soliciten, o ante las cuales se encuentre procedente formular, denuncia,
          demanda, convocatoria a arbitramento, queja o reclamaci&oacute;n, </span></p>
        <p class="c1"><span class="c0">e) toda persona natural que CLIENTE/USUARIO expresamente autorice. </span></p>
        <p class="c1"><span class="c0">f) Aliados comerciales. Adicionalmente CLIENTE/USUARIO autoriza todo tipo de
          tratamiento que sea necesario para cumplir las finalidades anteriormente mencionadas y declara que ha sido
          informado de la posibilidad que tiene como titular de la informaci&oacute;n de conocer en cualquier momento
          los datos de su titularidad con los que cuenta ANTECH, con el fin de actualizarlos, corregirlos o
          rectificarlos, as&iacute; como de la posibilidad que tiene de solicitar su eliminaci&oacute;n o
          revocaci&oacute;n, en los casos previstos en la ley. Adicionalmente CLIENTE/USUARIO declara que ha sido
          informado de las pol&iacute;ticas y procedimientos que tiene ANTECH para el manejo de su informaci&oacute;n
          y conoce que las mismas pueden ser consultadas permanentemente en las oficinas principales del
          ANTECH.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">7. MEDIOS DE CONTACTO DEL USUARIO CON ANTECH</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">El Usuario podr&aacute; contactarse a trav&eacute;s de los canales de atenci&oacute;n
          debidamente autorizados por la sociedad ANTECH y que se encuentran publicados en la plataforma web
          www.creditechantares.com, tales como:</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Servicio al cliente: +57 3106239462</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">L&iacute;nea de pagos y cobranzas: +57 3106239462</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Recaudos: +57 3106239462 o escribir al WhatsApp +57 3106239462 en
          Bogot&aacute;</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Chat de WhatsApp: +57 3106239462 habilitado en la p&aacute;gina web
          <a target="_blank" href="https://creditechantares.com/">https://creditechantares.com/</a></span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Radicaci&oacute;n de PQR&acute;S: correo electr&oacute;nico
          info@creditechantares.com</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Radicaci&oacute;n de correspondencia f&iacute;sica, a la direcci&oacute;n Calle 93 #
          15-51 Oficina 104 Edificio 93 -Bogot&aacute;- </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">8. AUTONOM&Iacute;A E INDEPENDENCIA</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH, sus empleados y colaboradores, actuar&aacute;n por su propia cuenta, con
          absoluta autonom&iacute;a y no estar&aacute;n sometidos a subordinaci&oacute;n por parte de los Usuarios.
          Sus derechos se limitar&aacute;n de acuerdo con la naturaleza de la relaci&oacute;n entre las Partes a
          exigir el cumplimiento de las obligaciones de los Usuarios y al pago del valor estipulado en la plataforma o
          cualquier otro costo que se estipule por medio de los T&eacute;rminos y Condiciones, o para el cumplimiento
          de las finalidades de las relaciones jur&iacute;dicas aqu&iacute; plasmadas. Las personas que participen en
          la ejecuci&oacute;n de la relaci&oacute;n entre las Partes son empleados y/ o contratistas exclusivamente de
          ANTECH y no tendr&aacute;n relaci&oacute;n alguna de empleo con los Usuarios/Clientes.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">9. RELACI&Oacute;N ENTRE LAS PARTES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Estos T&eacute;rminos y condiciones son potestativos y nada de lo incluido en el
          mismo deber&aacute; interpretarse en el sentido de que se crea una relaci&oacute;n de trabajo,
          patr&oacute;n, empleado, socio y asociado entre ANTECH y los Usuarios. Ninguna de las Partes estar&aacute;
          facultada para representar y obligar a la otra de manera alguna, y cada una de las Partes ser&aacute;n
          responsables exclusivamente de sus propios actos. Por el contrario, la relaci&oacute;n jur&iacute;dica
          desarrollada en las presentes cl&aacute;usulas corresponder&aacute; a un contrato t&iacute;pico de mutuo
          mercantil bajo las especificidades aqu&iacute; se&ntilde;aladas.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">10. RESPONSABILIDAD ANTECH</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Responder&aacute; &uacute;nica y exclusivamente frente a terceros y frente a los
          Usuarios en aquellos casos en que se demuestre culpa grave o dolo por parte de ANTECH, en la causa que
          origin&oacute; el da&ntilde;o o evento por el que se deba responder. Su responsabilidad se limita
          exclusivamente a las obligaciones que se encuentran consagradas en estos T&eacute;rminos y Condiciones o
          aquellas que pertenezcan a la tipicidad de los contratos de mutuo mercantil. Los Usuarios/Cliente al
          utilizar la p&aacute;gina web aceptan la limitaci&oacute;n de responsabilidad asignada a ANTECH. </span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">11. CASO FORTUITO O FUERZA MAYOR</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Ninguna de las Partes ser&aacute; responsable por incumplimiento de los presentes
          T&eacute;rminos cuando &eacute;ste sea motivado por caso fortuito o fuerza mayor. No obstante, la existencia
          de caso fortuito o fuerza mayor no ser&aacute;n motivo para que la Parte afectada no pague oportunamente las
          cantidades o cumpla con las obligaciones consignadas en los T&eacute;rminos y Condiciones, previas a la
          aparici&oacute;n de estas situaciones.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">12. INTERPRETACI&Oacute;N</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Los presentes T&eacute;rminos y Condiciones se interpretar&aacute;n de conformidad
          con el principio de la buena fe y atendiendo a la finalidad del objeto por virtud del cual se ha contratado,
          incorporando as&iacute; los criterios que de que tratan los art&iacute;culos propios del mutuo mercantil,
          as&iacute; como de las disposiciones normativas y jurisprudenciales en relaci&oacute;n con los contratos
          at&iacute;picos, especialmente, el contrato de comisi&oacute;n mercantil. No obstante, se consultar&aacute;
          primero a la intenci&oacute;n antes que a la literalidad de las cl&aacute;usulas convenidas. Estos
          T&eacute;rminos y Condiciones se regir&aacute;n e interpretar&aacute;n por las leyes aplicables a la
          Rep&uacute;blica de Colombia, particularmente por lo dispuesto en el C&oacute;digo de Comercio Colombiano y
          la jurisprudencia desarrollada por la Corte Constitucional, la Corte Suprema de Justicia, el Consejo de
          Estado y la Superintendencia de Industria y Comercio en desarrollo de sus funciones jurisdiccionales.</span>
        </p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">13. SARLAFT</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Las Partes declaran que sus negocios y los recursos utilizados para la
          ejecuci&oacute;n y pago de los Servicios contratados, no provienen ni se destinar&aacute;n al ejercicio de
          ninguna actividad il&iacute;cita, lavado de activos o financiaci&oacute;n del terrorismo. Asimismo, se
          comprometen a entregar toda la informaci&oacute;n que les sea solicitada para dar cumplimiento a las
          disposiciones relacionadas con la prevenci&oacute;n del lavado de activos y financiaci&oacute;n del
          terrorismo y declaran que la misma es veraz y verificable. Las Partes se obligan a realizar todas las
          actividades encaminadas a asegurar que todos sus socios, empleados, contratistas, administradores, clientes,
          proveedores y los recursos de &eacute;stos, no se encuentren relacionados o provengan de actividades
          il&iacute;citas; En todo caso, si durante el plazo de vigencia de los presentes T&eacute;rminos y
          Condiciones las Partes o alguno de sus socios, administradores, clientes, proveedores, contratistas o
          empleados llegar&aacute;n a resultar inmiscuidos en una investigaci&oacute;n de cualquier tipo como penal,
          administrativa, o de cualquier otra &iacute;ndole, relacionada con actividades il&iacute;citas, lavado de
          activos o financiaci&oacute;n del terrorismo, o fuesen incluidos en listas de control como las de la ONU,
          OFAC, etc., cualquiera de las Partes tiene derecho a terminar unilateralmente la relaci&oacute;n entre las
          Partes.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">14. MODIFICACIONES</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">ANTECH podr&aacute;, a su sola y absoluta discreci&oacute;n, cambiar por s&iacute; y
          sin aviso previo, estos T&eacute;rminos y Condiciones. Sin embargo, tales cambios s&oacute;lo se
          aplicar&aacute;n desde el momento en que sean publicados en la Plataforma y regir&aacute;n desde dicho
          momento. A menos que se especifique lo contrario en estos T&eacute;rminos de Uso, todos los avisos o
          modificaciones ser&aacute;n considerados debidamente entregados desde el momento de su publicaci&oacute;n en
          la Plataforma, o bien desde el momento en que sea notificado al Usuario, seg&uacute;n corresponda. Si el
          Usuario tiene alguna duda respecto de los T&eacute;rminos y Condiciones, Pol&iacute;tica de Privacidad, uso
          de la Plataforma o de su Perfil, podr&aacute; ponerse en contacto con ANTECH v&iacute;a el correo
          electr&oacute;nico. Los mensajes ser&aacute;n atendidos a la mayor brevedad posible. Este canal ser&aacute;
          el de respuesta m&aacute;s r&aacute;pida, siendo los dem&aacute;s datos de contacto de uso exclusivamente
          administrativo.</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c2">15. ACUERDO COMPLETO</span></p>
        <p class="c1 c4"><span class="c0"></span></p>
        <p class="c1"><span class="c0">Las Partes manifiestan que estos T&eacute;rminos y Condiciones constituyen un acuerdo
          completo y total acerca de su objeto, reemplazando cualquier otro contrato verbal o escrito celebrado entre
          las mismas Partes con anterioridad respecto del mismo objeto, inclusive respecto de contratos anteriores que
          se hayan cedido.</span></p>
      </div>
    </div>
  );
};

export default Terms;
