import React from 'react';
import logo from './images/antareslogo2x.png';
import './Static.scss';
import SingleHeader from './Components/SingleHeader/SingleHeader';
import HomeWidget from './Components/HomeWidget/HomeWidget';
import logoHome from './images/home-widget.png';

const Policies = () => {
  return (
    <div>
      <SingleHeader data={{
        logoUrl : logo
      }}
      />
      <HomeWidget
        logoHome={logoHome}
      />
      <div className="static">
        
        <p className="c9"><span className="c1">POL&Iacute;TICA PARA EL TRATAMIENTO DE LA INFORMACI&Oacute;N Y LOS DATOS PERSONALES -
          INVERSIONES ANTARES-TECH SAS</span></p>
        <p className="c9"><span className="c1">NIT. 901.774.639-5</span></p>
        <p className="c9 c14"><span className="c1"></span></p>
        <p className="c7"><span className="c8">INVERSIONES ANTARES-TECH SAS.</span><span className="c2">, distinguida con el NIT.
          901.774.639-5, en adelante </span><span className="c8">ANTECH</span><span className="c0">, adopta su pol&iacute;tica
            para el tratamiento y la protecci&oacute;n de la informaci&oacute;n y los datos personales, dando
            cumplimiento a lo establecido en la Constituci&oacute;n Pol&iacute;tica, en la Ley 1581 de 2012, el Decreto
            1074 de 2015, conocido como el &ldquo;Decreto &Uacute;nico Reglamentario del Sector Comercio, Industria y
            Turismo&rdquo;, por medio del cual se compilan los Decretos Reglamentarios 1377 de 2013 y 886 de 2014,
            as&iacute; como, en lo pertinente a la Circular &Uacute;nica de la Superintendencia de Industria y Comercio,
            T&iacute;tulo V. </span></p>
        <p className="c3"><span className="c1"></span></p>
        <p className="c9"><span className="c1">GENERALIDADES</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c2">La sociedad &nbsp;</span><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span
          className="c2">, reconociendo el derecho constitucional consagrado en el art&iacute;culo 15 de la
          Constituci&oacute;n Pol&iacute;tica, a trav&eacute;s del cual todas las personas tienen derecho a conocer,
          actualizar y rectificar la informaci&oacute;n que se haya recogido sobre ellas en bancos de datos y en
          archivos de entidades p&uacute;blicas y privadas, y d&aacute;ndole aplicaci&oacute;n a la Ley 1581 de 2012
          por medio de la cual se establecen las disposiciones generales para la protecci&oacute;n de datos
          personales, elabora la presente </span><span className="c8">POL&Iacute;TICA PARA EL TRATAMIENTO DE LOS DATOS
            PERSONALES</span><span className="c0">, teniendo en cuenta que es de car&aacute;cter obligatorio para todas las
              personas naturales y jur&iacute;dicas que realicen el tratamiento de lo datos personales registrados en las
              bases de datos de las entidades. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c8">ANTECH </span><span className="c2">&nbsp;pone de presente a todas las personas que hagan
          uso de nuestras diferentes plataformas, que los datos personales que se obtengan en atenci&oacute;n a las
          operaciones de PRESTACI&Oacute;N DE SERVICIOS DE FINANCIAMIENTO, CREDITOS DE BAJA MONTA Y CREDITOS
          PERSONALES, ser&aacute;n tratados de acuerdo con los principios establecidos en la Ley 1581 de 2012 y las
          dem&aacute;s normas que traten y regulen la materia. Para todos los fines pertinentes, el domicilio de
        </span><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span className="c0">&nbsp;se encuentra en la
          direcci&oacute;n Calle 93 # 15-51 Oficina 104, en la ciudad de Bogot&aacute; D.C., su n&uacute;mero de
          tel&eacute;fono es +573106239462, su correo electr&oacute;nico es info@creditechantares.com y la
          p&aacute;gina web es <a target="_blank" href="https://creditechantares.com/">https://creditechantares.com/</a></span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">OBJETIVO DE LA POL&Iacute;TICA</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">El objetivo de la presente Pol&iacute;tica es establecer de la manera completa los
          criterios y lineamientos necesarios para la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n y
          supresi&oacute;n de los datos recolectados durante las operaciones de PRESTACI&Oacute;N DE SERVICIOS DE
          FINANCIAMIENTO, CREDITOS DE BAJA MONTA Y CREDITOS PERSONALES mano y dem&aacute;s actividades que hagan parte
          nuestro giro ordinario, y que ser&aacute;n tratados por ANTECH, a fin de dar cumplimiento a la Ley.</span>
        </p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">De igual manera, se establece que ANTECH a trav&eacute;s de los criterios y
          lineamientos que sean expuestos a lo largo del presente documento, busca garantizar la reserva de la
          informaci&oacute;n y la seguridad sobre el tratamiento que se le dar&aacute; a los datos personales de los
          clientes, contratistas, proveedores y dem&aacute;s personas que suministren informaci&oacute;n a la
          sociedad, dando cumplimiento a los principios, derechos, libertades y garant&iacute;as que la Ley consagra.
        </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">DESTINATARIO DE LA POL&Iacute;TICA</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Esta pol&iacute;tica se aplicar&aacute; a todas las bases de datos tanto
          f&iacute;sicas como digitales, que contengan datos personales y que sean objeto de tratamiento por parte de
          ANTECH, quien se considera como responsable. As&iacute; mismo, aplicar&aacute; en aquellos casos en que
          operen como encargados del tratamiento de datos personales. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">La pol&iacute;tica se dirige a las personas naturales y jur&iacute;dicas en general
          para que tengan a su disposici&oacute;n la informaci&oacute;n necesaria y pertinente sobre los diferentes
          tratamientos y fines sobre los cuales ser&aacute;n objeto sus datos, as&iacute; como los derechos que le
          asisten como titulares datos personales, para que puedan ejercerlos ante ANTECH, cuando este tenga en rol de
          responsable del tratamiento de sus datos personales. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">ALCANCE</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">A trav&eacute;s de la presente se busca dar un tr&aacute;mite expedito y legal a las
          diferentes solicitudes y reclamaciones hechas por los titulares de la informaci&oacute;n, as&iacute; como
          por sus causahabientes u otra persona que cuenten con la debida autorizaci&oacute;n (representantes y/o
          apoderado).</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Dar cumplimiento a las exigencias de la normatividad vigente en materia de
          protecci&oacute;n de datos personales, as&iacute; como cualquier exigencia originada en el principio de
          responsabilidad demostrada.</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Brindar la debida protecci&oacute;n a los intereses y necesidades de los titulares de
          la informaci&oacute;n personal tratada por ANTECH. &nbsp;</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">DEFINICIONES </span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Las siguientes definiciones se generan con el fin de permitir una mejor
          comprensi&oacute;n por parte de los destinatarios de la presente pol&iacute;tica, las cuales parten de las
          definiciones que han sido adoptadas por el marco normativo que regula la materia: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ul className="c10 lst-kix_list_5-0 start">
          <li className="c4 li-bullet-0"><span className="c8">Autorizaci&oacute;n: </span><span className="c0">Consentimiento previo,
            expreso e informado del titular, para llevar a cabo el tratamiento de datos personales. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Base de datos:</span><span className="c0">&nbsp;Conjunto organizado de
            datos personales que ser&aacute; objeto del tratamiento. Podr&aacute; estar automatizada, es decir, su
            registro se lleva a trav&eacute;s de medios electr&oacute;nicos, o podr&aacute; ser manual, lo que
            significa que, la informaci&oacute;n podr&aacute; estar de manera f&iacute;sica en libro o documentos.
          </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Datos personales: </span><span className="c0">Se considera como dato
            personal cualquier pieza de informaci&oacute;n que est&eacute; vinculada a una o varias personas, sean
            naturales o jur&iacute;dicas. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Dato p&uacute;blico:</span><span className="c0">&nbsp;De acuerdo con la
            normatividad, se entiende por datos p&uacute;blicos aquellos que no sean semiprivados o privados como,
            por ejemplo, los datos contenidos en documentos p&uacute;blicos, sentencias judiciales ejecutoriadas que
            no est&eacute;n sometidas a reserva y los relativos al estado civil de las personas</span></li>
          <li className="c4 li-bullet-0"><span className="c8">Dato semiprivado: </span><span className="c0">Se entender&aacute; por
            dato semiprivado aquel que no tiene naturaleza &iacute;ntima, reservada, ni p&uacute;blica, cuyo
            conocimiento o divulgaci&oacute;n puede interesar no s&oacute;lo a su titular, sino a cierto sector o
            grupo de personas. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Dato privado:</span><span className="c0">&nbsp;Es aquel dato que, por su
            naturaleza &iacute;ntima o reservada, s&oacute;lo resulta ser relevante para el titular de la
            informaci&oacute;n. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Dato sensible:</span><span className="c0">&nbsp;Se entender&aacute; como
            dato sensible aquel que afecta la intimidad del titular o cuyo uso indebido puede generar su
            discriminaci&oacute;n.</span></li>
          <li className="c4 li-bullet-0"><span className="c8">Encargado del tratamiento:</span><span className="c0">&nbsp;Ser&aacute;
            toda persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en
            asociaci&oacute;n con otros, realice el tratamiento de datos personales por cuenta del responsable del
            tratamiento. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Responsable del tratamiento: </span><span className="c0">Ser&aacute;
            toda persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio
            con otros, decida sobre la base de datos y/o el tratamiento de datos. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Titular de la informaci&oacute;n:</span><span className="c0">&nbsp;Para
            efectos de la presente Pol&iacute;tica para el Tratamiento de Datos Personales, el titular ser&aacute;
            toda persona natural o jur&iacute;dica a quien se refiere la informaci&oacute;n que reposa en las bases
            de datos de ANTECH y que tiene la facultad de ejercer su derecho al Habeas Data.</span></li>
          <li className="c4 li-bullet-0"><span className="c8">Tratamiento:</span><span className="c0">&nbsp;Se entender&aacute; por
            tratamiento toda operaci&oacute;n o conjunto de operaciones que se haga sobre datos personales, tales
            como la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n. </span></li>
        </ul>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">PRINCIPIOS GENERALES</span></p>
        <p className="c9 c14"><span className="c1"></span></p>
        <p className="c7"><span className="c0">Los principios aqu&iacute; se&ntilde;alados se acogen a la normatividad vigente (Ley
          1581 de 2012 y Ley 1266 de 2008) en el territorio colombiano para garantizar la protecci&oacute;n de los
          datos personales que sean recolectados por parte de la sociedad en el desarrollo de su objeto social y se
          aplicar&aacute;n de manera integral y arm&oacute;nica: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_13-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c8">Principio de legalidad en materia de tratamiento de datos:
          </span><span className="c0">Todo tratamiento que se haga sobre los datos personales de los titulares de la
            informaci&oacute;n se realizar&aacute; bajo los presupuestas establecido por la Ley en la materia.
            </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de finalidad: </span><span className="c0">Todo tratamiento que
            se haga sobre los datos personales, debe obedecer a una finalidad que debe estar permitida en la Ley y
            ser previamente comunicada a los titulares de la informaci&oacute;n. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de libertad: </span><span className="c0">Todo tipo de
            tratamiento que se haga sobre los datos personales, &uacute;nicamente se realizar&aacute; cuando exista
            el consentimiento previo, expreso e informado por parte de los titulares. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de veracidad o calidad: </span><span className="c0">Toda la
            informaci&oacute;n que sea sometida al tratamiento sea veraz, completa, exacta, actualizada, comprobable
            y comprensible, teniendo en cuenta que la Ley proh&iacute;be el tratamiento de datos parciales,
            incompletos, fraccionados o que pueda inducir a error. &nbsp;</span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de transparencia: </span><span className="c0">Durante el
            tratamiento de los datos se garantizar&aacute; el derecho del titular a obtener por parte de los
            responsables o encargados informaci&oacute;n acerca de la existencia de datos que sean de su
            inter&eacute;s. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de acceso y circulaci&oacute;n restringida:</span><span
            className="c0">&nbsp;El tratamiento de los datos personales se sujeta y se limita a la naturaleza de los
            datos y las disposiciones establecidas en la Ley y la Constituci&oacute;n Pol&iacute;tica de Colombia.
            Adem&aacute;s, los datos que se recolecten y sean tratados no podr&aacute;n estar disponibles en
            internet u otros medios de divulgaci&oacute;n o comunicaci&oacute;n masiva. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de Seguridad:</span><span className="c0">&nbsp;El tratamiento
            de los datos que se realice por parte del responsable o el encargado de la informaci&oacute;n
            tendr&aacute; que hacerse bajo la debidas medidas t&eacute;cnicas, humanas y administrativas que sean
            necesarias para garantizar la seguridad de los datos personales. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de confidencialidad: </span><span className="c0">Todas las
            personas que hagan parte del tratamiento de datos personales y que no tengan la naturaleza de
            p&uacute;blica, est&aacute;n obligadas a garantizar la reserva de la informaci&oacute;n. </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de temporalidad:</span><span className="c0">&nbsp;La
            informaci&oacute;n que haya sido suministrada por parte del titular de los datos no ser&aacute;
            suministrada a usuarios o terceros cuando deje de servir para la finalidad del banco de datos. </span>
          </li>
          <li className="c4 li-bullet-0"><span className="c8">Interpretaci&oacute;n integral de los derechos constitucionales:
          </span><span className="c0">De conformidad con la Ley, el tratamiento de los datos personales que se realice
            tendr&aacute; en cuenta de manera integral los derechos constitucionales al habeas data, al buen nombre,
            a la honra, a la intimidad y a la informaci&oacute;n </span></li>
          <li className="c4 li-bullet-0"><span className="c8">Principio de necesidad:</span><span className="c0">&nbsp;Los datos que
            sean recolectados ser&aacute;n exclusivamente aquellos que sean necesarios para poder darle cumplimiento
            a las finalidades perseguidas por las bases de datos. </span></li>
        </ol>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c9 c6"><span className="c1">SOBRE LA AUTORIZACI&Oacute;N</span></p>
        <p className="c9 c14 c6"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Se se&ntilde;ala que en el tratamiento de los datos personales se requiere la
          autorizaci&oacute;n previa e informada del titular, la cual puede ser obtenida por cualquier medio que pueda
          ser objeto de consulta posterior. Por lo tanto, los titulares de los datos personales que sean suministrados
          a ANTECH, por cualquier medio escrito, f&iacute;sico y electr&oacute;nico, aceptan el tratamiento de los
          mismos y autorizan a ANTECH a su uso cuando los datos sean proporcionados al ingresar de manera voluntaria a
          las instalaciones o al hacer uso de los servicios de conformidad con los t&eacute;rminos de esta
          pol&iacute;tica. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_3-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c8 c11">Casos en que no es necesaria la autorizaci&oacute;n por parte
            del titular: </span></li>
        </ol>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c6 c7"><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span className="c0">&nbsp;no requerir&aacute;
          autorizaci&oacute;n previa del titular para realizar cualquier tratamiento de datos personales cuando se
          trate de la siguiente informaci&oacute;n: 1) requerida por orden judicial, 2) se trate de datos de
          naturaleza p&uacute;blica, 3) se trate de informaci&oacute;n a la que se deba de acceder cuando se trate de
          urgencias m&eacute;dicas o sanitarias, 4) el tratamiento de la informaci&oacute;n sea autorizado por la Ley
          para fines hist&oacute;ricos, estad&iacute;stico o cient&iacute;ficos, 5) cuando se trate de datos
          relacionados con el Registro Civil de las Personas. </span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c7 c6"><span className="c0">De igual manera, cuando se tenga acceso a los datos personales sin que medie
          autorizaci&oacute;n previa, ANTECH seguir&aacute; cumpliendo con las disposiciones contenidas en la Ley 1581
          de 2012 y dem&aacute;s normas concordantes y vigentes. </span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_3-0" start="2">
          <li className="c6 c16 li-bullet-0"><span className="c11 c8">Tratamiento de datos sensibles </span></li>
        </ol>
        <p className="c6 c12"><span className="c1"></span></p>
        <p className="c6 c17"><span className="c0">De conformidad con las normas vigentes en Colombia, se encuentra prohibido el
          tratamiento de datos sensibles, excepto en los siguientes casos: </span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <ul className="c10 lst-kix_list_12-0 start">
          <li className="c4 li-bullet-0"><span className="c0">Cuando el titular de los datos haya dado su autorizaci&oacute;n
            expl&iacute;cita para dicho tratamiento, salvo en los casos que por Ley no sea requerido el otorgamiento
            de dicha autorizaci&oacute;n.</span></li>
          <li className="c4 li-bullet-0"><span className="c0">El tratamiento sea necesario para salvaguardar el inter&eacute;s
            vital del titular y este se encuentre f&iacute;sica o jur&iacute;dicamente incapacitado. En estos casos,
            los representantes legales ser&aacute;n quienes deban otorgar la debida autorizaci&oacute;n. </span>
          </li>
          <li className="c4 li-bullet-0"><span className="c0">El tratamiento tenga una finalidad hist&oacute;rica,
            estad&iacute;stica o cient&iacute;fica. En este evento, se tendr&aacute;n que adoptar las medidas
            conducentes para la supresi&oacute;n de la identidad de los titulares.</span></li>
        </ul>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_3-0" start="3">
          <li className="c4 li-bullet-0"><span className="c11 c8">Autorizaci&oacute;n especial para el tratamiento de los datos
            sensibles</span></li>
        </ol>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c7 c6"><span className="c0">ANTECH informar&aacute; a trav&eacute;s de los diversos medios de obtenci&oacute;n
          de la autorizaci&oacute;n a todos sus titulares que, en virtud de la Ley 1581 de 2012 y dem&aacute;s normas
          reglamentarias, no est&aacute;n obligados a otorgar la autorizaci&oacute;n para el tratamiento de datos
          sensibles. </span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c7 c6"><span className="c0">En caso de que haya lugar al tratamiento de datos relativos a la salud, ANTECH
          implementar&aacute; las medidas necesarias para proteger la confidencialidad de la
          informaci&oacute;n.</span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c7 c6"><span className="c0">Los datos biom&eacute;tricos que sean tratados tendr&aacute;n como finalidad la
          identificaci&oacute;n de las personas, la seguridad, el cumplimiento de obligaciones legales y la adecuada
          prestaci&oacute;n de los servicios ofertados por ANTECH </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c2">Adicional, </span><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span
          className="c0">&nbsp;al momento de solicitar al titular la autorizaci&oacute;n para realizar el tratamiento de
          los datos personales, informar de manera clara y expresa: 1) el tratamiento al cual ser&aacute;n sometidos
          los datos personales y su finalidad, 2) el car&aacute;cter facultativo de la respuesta a las preguntas que
          le sean hechas, cuando estas versen sobre datos sensibles o sobre los datos de ni&ntilde;os, ni&ntilde;as y
          adolescentes, 3) los derechos que le asisten como titular, 4) la identificaci&oacute;n, direcci&oacute;n
          f&iacute;sica o electr&oacute;nica y tel&eacute;fono del responsable del tratamiento. En este caso, ANTECH
          como responsable del tratamiento conservar&aacute; prueba de que ha dado cumplimiento a lo presente en esta
          Pol&iacute;tica para el Tratamiento de Datos Personales y entregar&aacute; copia de la misma al titular
          cuando la haya solicitado. </span></p>
        <p className="c3 c6"><span className="c0"></span></p>
        <p className="c9 c6"><span className="c1">TRATAMIENTO DE DATOS DE NI&Ntilde;OS, NI&Ntilde;AS Y ADOLESCENTES Y SUS
          DERECHOS</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">El tratamiento de datos personales de ni&ntilde;os, ni&ntilde;as y adolescentes
          est&aacute; prohibido, excepto cuando se trate de datos de naturaleza p&uacute;blica, siempre y cuando,
          dicho tratamiento cumpla con los siguientes par&aacute;metros y/o requisitos: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_2-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c0">Respondan y respeten el inter&eacute;s superior de los ni&ntilde;os,
            ni&ntilde;as y adolescentes.</span></li>
          <li className="c4 li-bullet-0"><span className="c0">se asegure el respeto de sus derechos fundamentales. </span></li>
        </ol>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Cumplidos los anteriores requisitos, el representante legal de los ni&ntilde;os,
          ni&ntilde;as y adolescentes otorgar&aacute; la autorizaci&oacute;n, previo ejercicio del menor de su derecho
          a ser escuchado, opini&oacute;n que ser&aacute; valorada teniendo en cuenta la madurez, autonom&iacute;a y
          capacidad de entender el asunto. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c8">TRATAMIENTO Y FINALIDAD AL CUAL SER&Aacute;N SOMETIDOS LOS DATOS
          PERSONALES</span><span className="c0">&nbsp;</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">De acuerdo a los lineamientos de la Ley 1581 de 2012 y de conformidad con las
          autorizaciones que sean otorgadas por parte de los titulares de la informaci&oacute;n, ANTECH
          realizar&aacute; operaciones o un conjunto de operaciones, en las cuales se incluye la recolecci&oacute;n,
          almacenamiento, uso y/o supresi&oacute;n de datos personales. As&iacute; las cosas, el tratamiento de datos
          se realizar&aacute; exclusivamente para las finalidades que hayan sido autorizadas y previstas en la
          presente pol&iacute;tica y en las autorizaciones que hayan sido otorgadas por los titulares.</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">A modo general se advierte que los datos personales que sean recolectados y
          almacenados podr&aacute;n ser objeto de tratamiento en los siguientes &aacute;mbitos: 1) Gesti&oacute;n de
          clientes, proveedores y contratistas, e decir, el tratamiento de los datos se realizar&aacute; para los
          fines relacionados con el desarrollo con el desarrollo del proceso de gesti&oacute;n contractual bajo la
          normativa vigente para el suministro de bienes o servicios que requiera la sociedad para su funcionamiento,
          2) Gesti&oacute;n del capital humano en el desarrollo de los procesos de la sociedad, es decir,
          recolecci&oacute;n de informaci&oacute;n de personas naturales para procesos de selecci&oacute;n con la
          finalidad de contrataci&oacute;n. Motivo por el cual, los candidatos que env&iacute;an voluntariamente su
          hoja de vida, la cual contiene datos personales, facultan a la sociedad para el tratamiento de estos. La
          informaci&oacute;n relacionada con los empleados ser&aacute; tratada con la finalidad de dar cumplimiento a
          las obligaciones establecidas contractualmente. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_8-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c8 c13">Usuarios/Clientes ANTECH:</span><span className="c0">&nbsp;El
            tratamiento de los datos por parte de ANTECH tendr&aacute; las siguientes finalidades: 1) llevar
            informaci&oacute;n sobre los intereses de los clientes o usuarios de las plataformas de ANTECH, sobre
            los servicios y productos que la misma ofrece, 2) adelantar los tr&aacute;mites administrativos
            correspondientes a la gesti&oacute;n de los cr&eacute;ditos solicitados por aquellos, 3) realizar
            campa&ntilde;as, actividades de divulgaci&oacute;n y el env&iacute;o informaci&oacute;n que sean
            promovidas por parte de ANTECH, 4) gestionar informaci&oacute;n necesaria para el cumplimiento de
            obligaciones tributarias, contractuales, comerciales, contables, registro comercial, etc., 5) evaluar la
            calidad de los servicios y los bienes que se comercializan en el mercado, 6) transmitir la
            informaci&oacute;n a encargados nacionales o internacionales con los que tenga una relaci&oacute;n
            operativa y provean los servicios necesarias para la debida operaci&oacute;n de ANTECH, 7) las
            dem&aacute;s finalidades que se determinen en procesos de obtenci&oacute;n de datos personales para su
            tratamiento, y en todo caso de acuerdo con la Ley y en el marco de las funciones que le son atribuibles
            a ANTECH. </span></li>
        </ol>
        <p className="c3 c6"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_8-0" start="2">
          <li className="c4 li-bullet-0"><span className="c8 c13">Empleados de ANTECH:</span><span className="c0">&nbsp;El tratamiento
            de los datos personales que correspondan a los empleados de ANTECH, se realizar&aacute; de acuerdo a las
            siguientes finalidades: 1) realizar las actividades necesarias para cumplir con las obligaciones legales
            y contractuales que la sociedad tiene con sus empleados, 2) para el control del cumplimiento de los
            requisitos relacionados con el Sistema General de Seguridad Social de ANTECH, 3) conforme el directorio
            de empleados con el fin de facilitar los canales de comunicaci&oacute;n, 4) en caso de datos
            biom&eacute;tricos capturados a trav&eacute;s de sistemas de video vigilancia o grabaci&oacute;n, su
            tratamiento tendr&aacute; como finalidad la identificaci&oacute;n, seguridad y la prevenci&oacute;n de
            fraude interno y externo, 5) los datos personales de menores ser&aacute;n tratados con la finalidad de
            dar cumplimiento a las obligaciones legales, 6) para el caso de los participantes en convocatorias de
            selecci&oacute;n, los datos personales tratados tendr&aacute;n como finalidad adelantar las gestiones de
            los procesos de selecci&oacute;n; las hojas de vida se gestionar&aacute;n garantizando &nbsp;el
            principio de acceso restringido, 7) informar y comunicar las generalidades de los eventos que sean
            desarrollados por ANTECH por los medios y en las formas que se consideren convenientes, 8) gestionar la
            cadena presupuestal de ANTECH (pagos de la sociedad, emisi&oacute;n de certificados de ingresos y
            retenciones de personas naturales y jur&iacute;dicas, y relaci&oacute;n de pagos), 9) gestionar el
            proceso contable de ANTECH </span></li>
        </ol>
        <p className="c3 c6"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_8-0" start="3">
          <li className="c4 li-bullet-0"><span className="c8 c13">Proveedores/Contratistas de ANTECH: </span><span className="c0">El
            tratamiento por parte de ANTECH de los datos personales de lo proveedores o contratistas, se realizar
            con las siguientes finalidades: 1) cumplir con todos los fines relacionados con el objeto de los
            procesos de selecci&oacute;n, contractuales o relacionado con estos, 2) realizar todos los
            tr&aacute;mites internos y darle cumplimiento a obligaciones contables, tributarias y de ley, 3)
            gestionar la cadena presupuestal ANTECH, los pagos, la emisi&oacute;n de certificados de ingresos y
            retenciones de personas naturales y jur&iacute;dicas, y relaciones de pago, 4) gestionar el proceso
            contable de ANTECH, 5) realizar todas las actividades necesarias para el cumplimiento de las diferentes
            &nbsp;etapas contractuales en las relaciones con proveedores y contratistas, 6) expedir las
            certificaciones contractuales solicitadas por los contratistas de ANTECH o por terceros, 7) finalidades
            que se determinen en procesos de obtenci&oacute;n de datos personales para su tratamiento, y en todo
            caso de acuerdo con la Ley y en el marco del giro ordinario de los negocios de ANTECH. </span></li>
        </ol>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">TRANSFERENCIA Y TRANSMISI&Oacute;N DE DATOS PERSONALES </span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span className="c0">&nbsp;podr&aacute; transferir y
          transmitir los datos personales a terceros con quienes tenga relaci&oacute;n operativa y le provean de
          servicios necesarios para su debida operaci&oacute;n, o de conformidad con las funciones establecidas a su
          cargo en las leyes. En dichos supuestos, se adoptar&aacute;n las medidas necesarias para que las personas
          que tengan acceso a sus datos personales cumplan con la presente Pol&iacute;tica y con los principios de
          protecci&oacute;n de datos personales y obligaciones establecidas en la Ley. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">En todo caso, cuando ANTECH transmita los datos a uno o varios encargados ubicados
          dentro o fuera del territorio de la Rep&uacute;blica de Colombia, establecer&aacute; cl&aacute;usulas
          contractuales o celebrar&aacute; un contrato de transmisi&oacute;n de datos personales en el que
          indicar&aacute;: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_1-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c0">Alcances del tratamiento.</span></li>
          <li className="c4 li-bullet-0"><span className="c0">Las actividades que el encargado realizar&aacute; por cuenta del
            responsable para el tratamiento de los datos personales.</span></li>
          <li className="c4 li-bullet-0"><span className="c0">Las obligaciones del encargado para con el titular y el responsable.
          </span></li>
        </ol>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Mediante dicho contrato el encargado se compromete a dar aplicaci&oacute;n a las
          obligaciones de responsable bajo la pol&iacute;tica de tratamiento de la informaci&oacute;n fijada por este,
          y a realizar el tratamiento de datos de acuerdo con la finalidad que los titulares hayan autorizado y con
          las leyes aplicables vigentes. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Adem&aacute;s de las obligaciones que impongan las normas que sean aplicables dentro
          el citado contrato, deber&aacute;n incluirse las siguientes obligaciones en cabeza del respectivo encargado:
        </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_6-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c0">Dar tratamiento, a nombre del responsable, a los datos personales
            conforme a los principios que los tutelan. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Salvaguardar la seguridad de las bases de datos en los que se
            contengan datos personales. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Guardar confidencialidad respecto del tratamiento de los datos
            personales. </span></li>
        </ol>
        <p className="c12"><span className="c0"></span></p>
        <p className="c9"><span className="c1">DERECHOS DE LOS TITULARE DE LA INFORMACI&Oacute;N</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Teniendo en cuenta que el derecho al Habeas Data tiene el car&aacute;cter de derecho
          fundamental y est&aacute; consagrado en el art&iacute;culo 15 de la Constituci&oacute;n Pol&iacute;tica de
          Colombia, los siguientes son derechos que puede ejercer el titular la informaci&oacute;n para garantizar su
          cumplimiento y que est&aacute;n consagrados en el art&iacute;culo 8 de la Ley 1581 de 2012: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_14-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c0">Conocer, actualizar y rectificar los datos personales frente a los
            responsables o encargados del tratamiento. Este derecho podr&aacute; ser ejercido frente a tos
            parciales, inexactos, incompletos, fraccionados y que puedan inducir a error, o cuando el tratamiento
            est&eacute; prohibido o no medie autorizaci&oacute;n. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Solicitar prueba de la autorizaci&oacute;n otorgada al responsable
            del tratamiento, excepto cuando se trate de aquellos datos que no requieren de previa
            autorizaci&oacute;n para ser tratados. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Ser informado por parte del encargado o el responsable del
            tratamiento, previa solicitud, sobre el uso que se le est&eacute; dando a los datos que han sido
            recolectados. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Presentar ante la Superintendencia de Industria y Comercio las
            respectivas quejas cuando considere que haya lugar a infracciones por parte del responsable o encargado
            del tratamiento de los datos personales. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n de
            los datos cuando en el tratamiento no se est&eacute;n respetando los principios, derechos y
            garant&iacute;as constitucionales y legales del titular de la informaci&oacute;n.</span></li>
          <li className="c4 li-bullet-0"><span className="c0">Acceder de forma gratuita a sus datos personales y que hayan sido
            objeto de tratamiento. </span></li>
        </ol>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Adicional, aparte del titular de la informaci&oacute;n, estos derechos tambi&eacute;n
          podr&aacute;n ser ejercidos por las siguientes personas: Por sus causahabientes, acreditando dicha calidad,
          los representantes o apoderados del titular previa acreditaci&oacute;n y aquellos terceros que sean
          autorizados por el titular o por la Ley. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">SUMINISTRO DE LA INFORMACI&Oacute;N</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Cuando los titulares de los datos personales eleven las respectivas peticiones para
          obtener informaci&oacute;n, la misma ser&aacute; suministrada principalmente por medios electr&oacute;nicos
          o por otros medios, si as&iacute; lo requiere el titular. En este caso, ANTECH suministrar&aacute; la
          informaci&oacute;n que sea solicitada sin barreras t&eacute;cnicas que impidan su acceso, garantizar&aacute;
          que el contenido sea de f&aacute;cil lectura y tendr&aacute; que corresponder de manera integral a toda la
          informaci&oacute;n que repose en las bases de datos de ANTECH. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Cabe mencionar, que de conformidad con el art&iacute;culo 13 de la Ley 1581 de 2012,
          que cuando la informaci&oacute;n re&uacute;na las debidas condiciones establecidas por la ley, podr&aacute;
          ser suministrada de igual manera a: </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_11-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c0">Los causahabientes del titular o sus representantes legales. </span>
          </li>
          <li className="c4 li-bullet-0"><span className="c0">A las entidades p&uacute;blicas o administrativas en ejercicio de
            sus funciones legales o por orden judicial. </span></li>
          <li className="c4 li-bullet-0"><span className="c0">A terceros autorizados por el titular o por la Ley. </span></li>
        </ol>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">DEBERES DE LOS RESPONSABLES Y ENCARGADOS DE LA INFORMACI&Oacute;N </span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">De conformidad con el t&iacute;tulo VI de la Ley 1581 de 2012, los responsables y
          encargados del tratamiento de los datos personales que se encuentren en las bases de datos de ANTECH
          deber&aacute;n cumplir con los siguientes deberes, sin perjuicio de las dem&aacute;s disposiciones que se
          prevean en la Ley: </span></p>
        <p className="c3"><span className="c11 c8"></span></p>
        <ol className="c10 lst-kix_list_9-0 start" start="1">
          <li className="c4 li-bullet-0"><span className="c11 c8">Responsable del tratamiento: </span></li>
        </ol>
        <ul className="c10 lst-kix_list_4-0 start">
          <li className="c5 li-bullet-0"><span className="c0">Garantizar al titular en todo tiempo el pleno y efectivo ejercicio
            del derecho de h&aacute;beas data.</span></li>
          <li className="c5 li-bullet-0"><span className="c0">Solicitar y conservar, en las condiciones que est&eacute;n previstas
            en la Ley, la copia de la respectiva autorizaci&oacute;n que sea otorgada por parte del titular. </span>
          </li>
          <li className="c5 li-bullet-0"><span className="c0">Informar debidamente al titular sobre la finalidad de la
            recolecci&oacute;n y los derechos que le asisten por virtud de la autorizaci&oacute;n otorgada.</span>
          </li>
          <li className="c5 li-bullet-0"><span className="c0">Conservar la informaci&oacute;n bajo las condiciones de seguridad
            necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o
            fraudulento. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Garantizar que la informaci&oacute;n que le sea suministrada al
            encargado del tratamiento de los datos sea veraz, completa, exacta, actualizada, comprobable y
            comprensible. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Actualizar la informaci&oacute;n, comunicando de forma oportuna al
            encargado del tratamiento de los datos todas las novedades que surjan y adoptando las medidas necesarias
            para que la informaci&oacute;n se mantenga actualizada. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Rectificar la informaci&oacute;n cuando sea incorrecta y comunicar
            esta situaci&oacute;n de manera oportuna al encargado del tratamiento. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Suministrar al encargado del tratamiento de los datos
            &uacute;nicamente la informaci&oacute;n que cuente con una previa autorizaci&oacute;n por parte del
            titular. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Exigir al encargado del tratamiento en todo momento el respeto a las
            condiciones de seguridad y privacidad de la informaci&oacute;n del titular. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Tramitar las consultas y reclamos formulados en los t&eacute;rminos
            se&ntilde;alados en la Ley de Protecci&oacute;n de Datos Personales. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Adoptar un manual interno de pol&iacute;ticas y procedimientos para
            garantizar el adecuado cumplimiento de la Ley y la debida atenci&oacute;n de las peticiones, consultas,
            quejas o reclamos. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Informar al encargado del tratamiento cuando determinada
            informaci&oacute;n se encuentre en estado de discusi&oacute;n por parte del titular. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Informar, previa solicitud, al titular sobre el uso que se
            est&aacute; dando a la informaci&oacute;n y datos personales suministrados. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Informar a la autoridad de protecci&oacute;n de datos
            -Superintendencia de Industria y Comercio- cuando se presenten violaciones a los c&oacute;digos de
            seguridad y existan riesgos para la administraci&oacute;n de la informaci&oacute;n. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Cumplir con las instrucciones y requerimientos que imparta la
            Superintendencia de Industria y Comercio. </span></li>
        </ul>
        <p className="c3 c6"><span className="c0"></span></p>
        <ol className="c10 lst-kix_list_9-0" start="2">
          <li className="c4 li-bullet-0"><span className="c8 c13">Encargado del tratamiento:</span><span className="c0">&nbsp;</span>
          </li>
        </ol>
        <ul className="c10 lst-kix_list_10-0 start">
          <li className="c5 li-bullet-0"><span className="c0">Garantizar al titular en todo tiempo el pleno y efectivo ejercicio
            del derecho de H&aacute;beas data.</span></li>
          <li className="c5 li-bullet-0"><span className="c0">Conservar la informaci&oacute;n bajo las condiciones de seguridad
            necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o
            fraudulento. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Realizar oportunamente la actualizaci&oacute;n, rectificaci&oacute;n
            o supresi&oacute;n de los datos en los t&eacute;rminos de la presente ley.</span></li>
          <li className="c5 li-bullet-0"><span className="c0">Actualizar la informaci&oacute;n que haya sido reportada por parte
            del responsable del tratamiento, dentro de los cinco d&iacute;as h&aacute;biles contados a partir de su
            recibo. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Tramitar las consultas y los reclamos formulados por los titulares
            en los t&eacute;rminos se&ntilde;alados en la Ley 1581 de 2012. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Adoptar un manual interno de pol&iacute;ticas y procedimientos para
            garantizar el adecuado cumplimiento de la presente ley y la debida atenci&oacute;n de consultas y
            reclamos por parte de los titulares. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Registrar en las bases de datos la descripci&oacute;n &ldquo;reclamo
            en tr&aacute;mite&rdquo; en la forma que se regule en la Ley y cuando la informaci&oacute;n se encuentre
            en discusi&oacute;n. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Abstenerse de circular informaci&oacute;n que est&eacute; siendo
            controvertida por el titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y
            Comercio. </span></li>
          <li className="c5 li-bullet-0"><span className="c0">Permitir el acceso a la informaci&oacute;n &uacute;nicamente a las
            personas que tienen autorizaci&oacute;n para acceder a ella.</span></li>
          <li className="c5 li-bullet-0"><span className="c0">Informar a la Superintendencia de Industria y Comercio cuando se
            hayan presentado violaciones a los c&oacute;digos de seguridad y existan riesgos en la
            administraci&oacute;n de la informaci&oacute;n.</span></li>
          <li className="c5 li-bullet-0"><span className="c0">Cumplir con las instrucciones y requerimientos que sean impartidos
            por parte de la Superintendencia de Industria y Comercio. </span></li>
        </ul>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">RESPONSABLE DEL TRATAMIENTO DE LA INFORMACI&Oacute;N</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c2">En este caso, teniendo en cuenta que el responsable del tratamiento de la
          informaci&oacute;n es toda persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute;
          misma o en asocio de otros, decida sobre las bases de datos y/o el tratamiento de los datos, y que el mismo
          est&aacute; sujeto a las disposiciones contenidas en la Ley 1581 de 2012 y dem&aacute;s disposiciones que
          regulen la materia, </span><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span className="c0">, sociedad
            comercial legalmente constituida, identificada con el NIT. 901.774.639-5 y domiciliada en la ciudad de
            Bogot&aacute; D.C. en la direcci&oacute;n Calle 93 N&ordm; 15-51 Oficina 104 Edificio 93, ser&aacute; la
            responsable del tratamiento de los datos personales que sean tratados. </span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c9"><span className="c1">&Aacute;REA ENCARGADA DE LA ATENCI&Oacute;N DE PETICIONES, CONSULTAS Y RECLAMOS
        </span></p>
        <p className="c9 c14"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Acogiendo las disposiciones de la Ley, a trav&eacute;s de las cuales se establece que
          los responsables y encargados deben adoptar manual para atender debidamente las consultas y reclamos, los
          titulares pueden tramitar las peticiones, consultas, quejas y reclamos de manera directa, expresa,
          inequ&iacute;voca y por escrito al &aacute;rea de comunicaci&oacute;n, aportando los documentos de soporte
          que sean requeridos para verificar la identidad y el car&aacute;cter que ostentan los solicitantes.
          Adicional, los datos del &aacute;rea encargada y sus canales de comunicaci&oacute;n son los siguientes:
        </span></p>
        <p className="c3"><span className="c0"></span></p>
        <ul className="c10 lst-kix_list_7-0 start">
          <li className="c4 li-bullet-0"><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span
            className="c2">&nbsp;ser&aacute; la encargada por medio de su representante legal o quien haga sus veces,
            por lo que, todas las solicitudes relacionadas al tratamiento de la informaci&oacute;n deber&aacute;n
            presentarse por medios escritos o a trav&eacute;s de medios electr&oacute;nicos ante el representante
            legal de la sociedad </span><span className="c8">INVERSIONES ANTARES-TECH SAS</span><span
              className="c0">&nbsp;en el correo electr&oacute;nico info@creditechantares.com o de manera presencial en la
              direcci&oacute;n Calle 93 N&ordm; 15-51 Oficina 104 Edificio 93en los siguientes horarios: 8:00 a.m. a
              5:00 pm jornada continua. &nbsp;</span></li>
        </ul>
        <p className="c3 c6"><span className="c0"></span></p>
        <ul className="c10 lst-kix_list_7-0">
          <li className="c4 li-bullet-0"><span className="c0">La solicitud deber&aacute; contener los siguientes requisitos
            m&iacute;nimos: El nombre completo del titular de la informaci&oacute;n con copia de su documento de
            identificaci&oacute;n, en caso de tratarse del representante y/o apoderado del titular, adjuntar
            constancia que acredite dicha representaci&oacute;n, la debida descripci&oacute;n de los hechos que dan
            lugar a la solicitud y la direcci&oacute;n y tel&eacute;fono de contacto para darle respuesta a la
            solicitud. </span></li>
        </ul>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">En caso de presentar inconsistencias en la solicitud presentada, ANTECH
          requerir&aacute; a la parte interesada dentro de los cinco (5) d&iacute;as h&aacute;biles siguientes a la
          recepci&oacute;n de la misma para que el titular o tercero autorizado subsane la solicitud. Transcurridos
          dos meses desde la fecha del requerimiento, sin que el solicitante presente la informaci&oacute;n requerida,
          se entender&aacute; que se ha desistido de la misma. As&iacute; mismo, cuando la solicitud realizada sea
          procedente, ANTECH atender&aacute; la misma dentro de los quince (15) d&iacute;as h&aacute;biles siguientes,
          contados a partir del d&iacute;a siguiente a la fecha de su recibo. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Cuando no sea posible para ANTECH atender las solicitudes en el t&eacute;rmino
          se&ntilde;alado, ANTECH informar&aacute; al solicitante los motivos y la fecha probable de respuesta dentro
          de los ocho d&iacute;as h&aacute;biles siguientes al vencimiento del primer t&eacute;rmino.</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Las solicitudes en las que se requiera suprimir los datos personales y revocar la
          autorizaci&oacute;n, no proceder&aacute;n cuando el titular de la informaci&oacute;n, sea persona natural o
          jur&iacute;dica, tenga un deber legal o contractual con la Sociedad.</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">VIGENCIA DE LA POL&Iacute;TICA PARA EL TRATAMIENTO DE DATOS PERSONALES</span></p>
        <p className="c9 c14"><span className="c1"></span></p>
        <p className="c7"><span className="c0">La presente Pol&iacute;tica para el Tratamiento de Datos personales rige a partir del
          2 de enero de 2024. No obstante, ANTECH se reserva el derecho a modificar la presente Pol&iacute;tica
          seg&uacute;n se requiera para el funcionamiento y cumplimiento normativo. </span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c7"><span className="c0">Las bases de datos en las que se registrar&aacute;n los datos personales
          tendr&aacute;n una vigencia igual al tiempo en que se mantenga y utilice la informaci&oacute;n para las
          finalidades descritas en esta pol&iacute;tica. Una vez se cumplan esas finalidades y siempre que no exista
          un deber legal o contractual de conservar su informaci&oacute;n, sus datos ser&aacute;n eliminados de
          nuestras bases de datos.</span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c3"><span className="c0"></span></p>
        <p className="c9"><span className="c1">CARLOS FERNANDO AMAYA RODR&Iacute;GUEZ </span></p>
        <p className="c9"><span className="c0">Representante Legal</span></p>
        <p className="c9"><span className="c1">INVERSIONES ANTARES-TECH SAS </span></p>
        <p className="c9"><span className="c0">NIT. 901.774.639 -5</span></p>
        <p className="c9 c14"><span className="c0"></span></p>
      </div>
    </div>
  );
};

export default Policies;
