import logo from './images/antareslogo2x.png';
import logoWhatsapp from './images/whatsapp-widget.png';
import heroImageDesktop1 from './images/img1.jpg';
import heroImageMobile1 from './images/img1_mb.jpg';
import heroImageDesktop2 from './images/img2.jpg';
import heroImageMobile2 from './images/img2_mb.jpg';
import heroImageDesktop3 from './images/img3.jpg';
import heroImageMobile3 from './images/img3_mb.jpg';
import comp1 from './images/split-1.jpg';
import infoDesktop from './images/info-dk.jpg';
import infoMobile from './images/info-mb.jpg';
import './Reset.scss';
import './App.scss';
import WhatsAppWidget from './Components/WhatsappWidget/WhatsAppWidget';
import Hero from './Components/Hero/Hero';
import Split from './Components/Split/Split';
import Cards from './Components/Cards/Cards';
import Infographic from './Components/Infographic/Infographic';
import Accordion from './Components/Accordion/Accordion';
import FormComponent from './Components/Form/Form';
import cardIcon1 from './images/icon-1.png';
import cardIcon2 from './images/icon-2.png';
import cardIcon3 from './images/icon-3.png';
import cardIcon4 from './images/icon-4.png';
import cardIcon5 from './images/icon-5.png';
import cardIcon6 from './images/icon-6.png';
import cardIcon7 from './images/icon-7.png';
import Header from './Components/Header/Header';

const comp1Alt = "comp1 alt";
const infoAlt = "infografic alt";
const accordionItems = [
  { title: '¿Puedo acceder a un crédito si estoy reportado en centrales de riesgo?', content: 'Sí, para nosotros lo más importante es la capacidad actual de pago del solicitante, en todo caso, la aprobación del crédito dependerá del monto solicitado y su plazo, así como del respaldo que ofrezcas para su pago.' },
  { title: '¿Cómo realizo los pagos de mi crédito?', content: 'Los pagos los realizas mediante transferencia y/o consignación bancaria a las cuentas que tiene habilitada ANTECH, pronto tendremos la posibilidad de realizar pago por PSE.' },
  { title: '¿Puedo refinanciar mi crédito?', content: 'No, las condiciones en las que presentes tu solicitud serán objeto de estudio por nuestro equipo y, por lo tanto, una vez aprobado y desembolsado el crédito no es posible presentar una refinanciación.' },
  { title: '¿Requiero codeudor o fiador para mi solicitud?', content: 'Para las solicitudes de crédito menores a SEIS MILLONES de pesos no es necesario la presentación de un codeudor o fiador, si el monto es superior, nuestros asesores pueden solicitar la presentación de una garantía para respaldar tu crédito.' },
  { title: '¿Por qué seré contactado por teléfono?', content: 'Con el fin de confirmar tu identidad y validar los datos que has dispuesto en tu solicitud.' },
  { title: '¿Puedo tener más de un crédito con ANTECH?', content: 'No, de momento sólo puedes tener un crédito activo con nosotros, en la medida que el mismo sea cancelado podrás acceder a una nueva solicitud.' },
  { title: '¿Puedo solicitar un crédito sin ser colombiano?', content: 'Sí, siempre y cuando acredites un estatus migratorio válido o legal en Colombia.' },
  { title: '¿Qué documentos debo firmar?', content: 'Deberás firmar el contrato de crédito, el pagaré junto con sus cartas de instrucciones, y en su caso libranza o autorización de descuento, o cesión de derechos económicos. Pueden requerirse otros documentos que serán informados por nuestros asesores.' },
  { title: '¿Puede ser rechazada mi solicitud?', content: 'El rechazo puede depender de varias cuestiones, por ejemplo, falta de capacidad de pago como resultado de la evaluación, datos inexactos, u cualquier otra circunstancia que no mitigue el riesgo.' },
  { title: '¿Cómo saber la fecha de pago de la cuota de mi crédito?', content: 'Al momento de la aprobación y desembolso, recibirás un correo electrónico con esta información, en todo caso, puedes contactar siempre con nuestros asesores para tener clara esta fecha.' },
  { title: '¿Dónde obtengo mi paz y salvo?', content: 'Efectuado el último pago, podrás solicitar tu paz y salvo por medio de nuestros asesores, el mismo será emitido en un plazo no mayor a 5 días hábiles.' },
  { title: '¿Qué pasa si no pago a tiempo?', content: 'El crédito entrará en mora junto con los intereses consigo que se deriven, conforme las condiciones informadas en el contrato de crédito, y de ser el caso podremos cobrar el total del crédito y hacer efectivas las garantías correspondientes.' },
  { title: '¿Cómo puedo contactarme con ANTECH?', content: 'Por medio de nuestros asesores en los canales de contacto indicados en nuestra página web.' },
];

function Home() {
  return (
    <div className="App">
      <WhatsAppWidget
        logoWhatsapp={logoWhatsapp}
      />
      <Header data={{
        logoUrl : logo
      }}
      />
      <Hero
        heroImages={[
          {
            desktop: heroImageDesktop1,
            mobile: heroImageMobile1
          },
          {
            desktop: heroImageDesktop2,
            mobile: heroImageMobile2
          },
          {
            desktop: heroImageDesktop3,
            mobile: heroImageMobile3
          },
        ]}
      />
      <Split
        data={{
          comp1 : comp1,
          comp1Alt: comp1Alt,
          subtitle : "ANTECH",
          content : [
            "Es el aliado financiero que utiliza la tecnología para prestar sus servicios: simple, seguro y que ayude a superar aquellos momentos en los que no tienes dinero. Es así como el trámite de cada crédito se realiza vía electrónica. Enfocado en aquellas personas naturales y jurídicas que requieran préstamos de dinero: al instante, seguros y con tasas menores al sector bancario.</br></br>Así las cosas, ANTECH es la plataforma por la cual empleados, independientes, pensionados, emprendedores, empresas, pueden apalancar económicamente sus actividades diarias, con préstamos ágiles, seguros y con tasas diferenciales al Banco tradicional.</br></br>Por último, en la práctica, nuestro sistema permite el desembolso en un plazo no mayor de 5 días hábiles desde el momento en que recibimos tu solicitud y se completa el papeleo correspondiente, datos que podrás verificar en la sección “Solicitar un crédito”. Ve a la sección, beneficios y conoce más de nosotros."
          ],
        }}
      />
      <Cards
        data={{
          title: "Cómo solicitar un crédito:",
          subtitle: "-Rápido y sencillo-",
          disclaimer: "Haz clic en los cuadros para más información",
          cards: [
            {
              icon: cardIcon1,
              title: "Diligenciar formulario",
              back: "Debes diligenciar el formulario dispuesto en nuestro portal web, en el que nos suministrarás algunos datos básicos tales como: nombres, documento de identidad, teléfono (fijo o móvil), correo electrónico, ingresos y gastos mensuales, tipo de vinculación (laboral – contrato de prestación de servicios) y datos de tu empleador."
            },
            {
              icon: cardIcon2,
              title: "Elige el monto",
              back: "El tope máximo que puede solicitarse es la suma de SEIS MILLONES DE PESOS. No obstante, una suma mayor puede ser solicitada y su aprobación está sujeta a verificación."
            },
            {
              icon: cardIcon3,
              title: "Elige el plazo",
              back: "El plazo deberás describirlo. Igualmente, dependiendo del estudio de crédito este se establecerá para cada caso en particular."
            },
            {
              icon: cardIcon4,
              title: "Trámite personalizado",
              back: "Luego de diligenciar el formulario, nos contactaremos contigo. Es decir, este es un trámite personalizado, en el que de acuerdo a la situación particular de cada persona, se solicitarán los documentos para el estudio de crédito, este tarda 2 días posterior a la remisión de la totalidad de documentos del solicitante."
            },
            {
              icon: cardIcon5,
              title: "FINANCIACIÓN, TÉRMINOS Y CONDICIONES",
              back: "De ser viable el crédito, ANTECH pondrá en conocimiento la financiación del crédito y los términos y condiciones."
            },
            {
              icon: cardIcon6,
              title: "Firma de documentos",
              back: "De ser aprobado por el solicitante los términos de financiación, recibirás los documentos firmados por ANTECH a tu correo electrónico, así como las indicaciones para realizar tus pagos."
            },
            {
              icon: cardIcon7,
              title: "Desembolso",
              back: "De ser aprobado el crédito, las firmas respectivas de los documentos de garantía en favor de la Empresa. Y cumplido ello, el desembolso en el término no mayor a 5 días."
            }
            ,
            {
              icon: logo,
              title: "ANTECH",
              back: "ANTECH"
            }
          ]
        }}
      />
      <Infographic
        data={{
          infoDesktop : infoDesktop,
          infoMobile : infoMobile,
          infoAlt: infoAlt,
          title : "BENEFICIOS DE SOLICITAR",
          span : "UN CRÉDITO CON NOSOTROS",
          content : "Luego de obtener toda la información y el estudio de riesgos, el solicitante obtendrá el préstamo en 5 días. Lo anterior, podrá ser depositado a su cuenta bancaria, u otra cuenta con la autorización previa remitida por el solicitante.",
        }}
      />
      <Accordion mainTitle="Preguntas frecuentes" items={accordionItems} />
      <FormComponent mainTitle="Formulario"/>
    </div>
  );
}

export default Home;
