import React from 'react';
import './InnerMenu.scss';

const InnerMenu = ({ onClose }) => {
  const handleItemClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const offset = 80;
      const topPos = targetElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: topPos,
        behavior: 'smooth'
      });
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <nav className="inner_menu">
      <ul>
        <li className="inner_menu_item"><a href="#about" onClick={(e) => handleItemClick(e, "about")}>Quienes Somos?</a></li>
        <li className="inner_menu_item"><a href="#credit" onClick={(e) => handleItemClick(e, "credit")}>¿Cómo solicitar un crédito?</a></li>
        <li className="inner_menu_item"><a href="#benefits" onClick={(e) => handleItemClick(e, "benefits")}>Beneficios</a></li>
        <li className="inner_menu_item"><a href="#questions" onClick={(e) => handleItemClick(e, "questions")}>Preguntas frecuentes</a></li>
        <li className="inner_menu_item"><a href="#contact" onClick={(e) => handleItemClick(e, "contact")}>Formulario</a></li>
      </ul>
    </nav>
  );
};

export default InnerMenu;