import logo from './images/antareslogo2x.png';
import Footer from './Components/Footer/Footer';
import logoSic from './images/logo-sic.png';

import { BrowserRouter as Router, Routes, Link, Route } from 'react-router-dom';
import Terms from './Terms';
import Policies from './Policies';
import Home from './Home';

function App() {
  document.title = "Antech - el aliado financiero que utiliza la tecnología para prestar sus servicios";

  // Update meta description dynamically
  const updateMetaDescription = (content) => {
    let metaDescription = document.querySelector("meta[name='description']");
    if (metaDescription) {
      metaDescription.setAttribute('content', content);
    } else {
      metaDescription = document.createElement('meta');
      metaDescription.setAttribute('name', 'description');
      metaDescription.setAttribute('content', content);
      document.head.appendChild(metaDescription);
    }
  };

  // Call the function with your new meta description
  updateMetaDescription("ANTECH es el aliado financiero que utiliza la tecnología para prestar sus servicios: simple, seguro y que ayude a superar aquellos momentos en los que no tienes dinero. Es así, que el trámite de cada crédito se realiza vía electrónica. Enfocado en aquellas personas naturales y jurídicas que requieran préstamos de dinero: al instante, seguros y con tasas menores al sector bancario. </br></br>Así las cosas, ANTECH es la plataforma por la cual: empleados, independientes, pensionados, emprendedores, empresas, pueden apalancar económicamente sus actividades diarias, con préstamos ágiles, seguros y con tasas diferenciales al Banco tradicional.</br></br>Por último, en la práctica, nuestro sistema permite el desembolso en un plazo no mayor de 5 días hábiles desde el momento en que recibimos tu solicitud y se completa el papeleo correspondiente, datos que podrás verificar en la sección “Solicitar un crédito”. Ve a la sección, beneficios y conoce más de nosotros.");
  
  return (
    <div className="App">
      
      <Router>
        <div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/terminos" element={<Terms />} />
            <Route path="/politicas" element={<Policies />} />
          </Routes>
          <Footer
            data={{
              logo : logo,
              logoSic: logoSic
            }}
          />
        </div>
      </Router>
    </div>
  );
}

export default App;
