import React, { useState } from 'react';
import './HamburgerMenu.scss';

const HamburgerMenu = ({ onToggle }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (onToggle) {
      onToggle(); // Call the passed function to notify the parent component
    }
  };

  return (
    <div className="hamburger_menu">
      <button className="hamburger_menu_icon" onClick={handleClick}>
        <span></span>
        <span></span>
      </button>
    </div>
  );
};

export default HamburgerMenu;