import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SingleHeader.scss';


const SingleHeader = ({ data }) => {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShrunk(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`single_header ${isShrunk ? 'shrunk' : ''}`}>
      <a href="/" className="header_logo">
        <img alt="Logo" src={data.logoUrl}/>
      </a>
    </div>
  );
};

SingleHeader.propTypes = {
  logoUrl: PropTypes.string
};

export default SingleHeader;