import React, { useState } from 'react';
import './Accordion.scss';

const Accordion = ({ mainTitle, items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleItem = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="questions" className="accordion">
      <h2 className='accordion_main-title'>{mainTitle}</h2>
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div className="accordion-item-title" onClick={() => toggleItem(index)}>
            {item.title}<span className='accordion-item-title_plus'>{activeIndex === index ? '-' : '+'}</span>
          </div>
          <div
            className={`accordion-item-content ${activeIndex === index ? 'open' : ''}`}
          >
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;