import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Header.scss';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import InnerMenu from '../InnerMenu/InnerMenu';


const Header = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setShrunk(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleItemClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const offset = 80;
      const topPos = targetElement.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: topPos,
        behavior: 'smooth'
      });
    }
  };

  const toggleInnerMenu = () => setIsOpen(!isOpen);

  const closeInnerMenu = () => setIsOpen(false); 

  return (
    <div className={`header ${isShrunk ? 'shrunk' : ''}`}>
      <a href="/" className="header_logo">
        <img alt="Logo" src={data.logoUrl}/>
      </a>
      <ul className='header_nav'>
        <li className="menu_item"><a href="#about" onClick={(e) => handleItemClick(e, "about")}>Quienes Somos?</a></li>
        <li className="menu_item"><a href="#credit" onClick={(e) => handleItemClick(e, "credit")}>¿Cómo solicitar un crédito?</a></li>
        <li className="menu_item"><a href="#benefits" onClick={(e) => handleItemClick(e, "benefits")}>Beneficios</a></li>
        <li className="menu_item"><a href="#questions" onClick={(e) => handleItemClick(e, "questions")}>Preguntas frecuentes</a></li>
        <li className="menu_item"><a href="#contact" onClick={(e) => handleItemClick(e, "contact")}>Formulario</a></li>
      </ul>
      <HamburgerMenu onToggle={toggleInnerMenu} />
      {isOpen && <InnerMenu onClose={closeInnerMenu} />}
    </div>
  );
};

Header.propTypes = {
  logoUrl: PropTypes.string
};

export default Header;