import React from 'react';
import './Infographic.scss';

const Infographic = ({ data }) => {
  return (
    <div id="benefits" className='infographic'>
      <div className='top'>
        <h2 className='infographic_main_title'>{data.title}<span className='infographic_subtitle'>{data.span}</span></h2>
        <p className='infographic_content'>
          {data.content}
        </p>
      </div>
      <div className='bottom'>
      <div className="infographic_image">
        <picture>
          {/* For desktop */}
          <source media="(min-width: 601px)" srcSet={data.infoDesktop} />
          {/* For mobile */}
          <source media="(max-width: 600px)" srcSet={data.infoMobile} />
          {/* Fallback image for older browsers or if the sources fail to load */}
          <img className="image" src={data.infoDesktop} alt="Responsive Image" />
        </picture>
      </div>
      </div>
    </div>
  );
};

export default Infographic;
