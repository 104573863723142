import React from 'react';
import './Split.scss';
import Image from '../Image/Image';

const Split = ({ data, comp1 }) => {
  return (
    <div id="about" className='split'>
      <div className='left'>
        <Image
          image={{
            url: data.comp1,
            alt: data.comp1Alt
          }}
        />
      </div>
      <div className='right'>
        <h2 className="main_title" dangerouslySetInnerHTML={{ __html: data.subtitle }}>
        </h2>
        {data.content.map((paragraph, index) => (
          <p className="content_block" key={index} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
        ))}
      </div>
      
    </div>
  );
};

export default Split;
