import React, { useState } from 'react';
import './WhatsAppWidget.scss';

const WhatsAppWidget = ({ logoWhatsapp }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleWidget = () => setIsOpen(!isOpen);

  return (
    <div className={`whatsapp_widget ${isOpen ? 'open' : ''}`} onClick={toggleWidget}>
      <img alt="Logo Whatsapp" src={logoWhatsapp} className="whatsapp_logo"/>
      {isOpen && (
        <div className="whatsapp_content">
          <a></a>
          <a target="_blank" href="https://wa.me/573106239462">¿Cómo podemos ayudarte?</a>
        </div>
      )}
    </div>
  );
};

export default WhatsAppWidget;
