import React, { useState } from 'react';
import './Form.scss';

const FormComponent = ({ mainTitle }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    docType: '',
    docNumber: '',
    address: '',
    email: '',
    cellphoneNumber: '',
    requestedValue: '',
    numberOfPayments: '',
    typeOfContract: '',
    howDidYouHearAboutUs: '',
    acceptDataTreatment: false,
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({ ...prevData, [name]: newValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);

    // Submit the form
    const form = e.target;
    const formFormData = new FormData(form);
    fetch(form.action, {
      method: form.method,
      body: formFormData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Handle successful form submission
        console.log('Form submitted successfully');
        setFormSubmitted(true); // Set formSubmitted to true upon successful submission
      })
      .catch((error) => {
        // Handle errors during form submission
        console.error('Error submitting form:', error);
      });
  };

  return (
    <div id="contact" className="form">
      <div className='inner_form'>
        <h2 className="form__main-title">{mainTitle}</h2>
        {formSubmitted ? (
          <h2 class="form__thank-you">Gracias, nos estaremos comunicando contigo</h2> // Display this after form submission
        ) : (
          <form action="https://formbold.com/s/3q4w1" method="POST" onSubmit={handleSubmit}>
            <label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="NOMBRES Y APELLIDOS"
                required
              />
            </label>
            <br />
            <label>
              <select name="docType" value={formData.docType} onChange={handleChange} required>
                <option value="">TIPO DE DOCUMENTO</option>
                <option value="Option 1">Cédula de ciudadania</option>
                <option value="Option 2">Cédula de extranjería</option>
                <option value="Option 3">Pasaporte</option>
              </select>
            </label>
            <br />
            <label>
              <input
                type="text"
                name="docNumber"
                value={formData.docNumber}
                onChange={handleChange}
                placeholder="NÚMERO DE DOCUMENTO"
                required
              />
            </label>
            <br />
            <label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="DIRECCIÓN"
                required
              />
            </label>
            <br />
            <label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="TU CORREO ELECTRÓNICO"
                required
              />
            </label>
            <br />
            <label>
              <input
                type="tel"
                name="cellphoneNumber"
                value={formData.cellphoneNumber}
                onChange={handleChange}
                placeholder="CELULAR"
                required
              />
            </label>
            <br />
            <label>
              <input
                type="number"
                name="requestedValue"
                value={formData.requestedValue}
                onChange={handleChange}
                placeholder="MONTO SOLICITADO"
                required
              />
            </label>
            <br />
            <label>
              <select name="numberOfPayments" value={formData.numberOfPayments} onChange={handleChange} required>
                <option value="">PLAZO</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
              </select>
            </label>
            <br />
            <label>
              <select name="typeOfContract" value={formData.typeOfContract} onChange={handleChange} required>
                <option value="">TIPO DE VINCULACIÓN</option>
                <option value="EMPLEADO">EMPLEADO</option>
                <option value="PRESTACIÓN DE SERVICIOS">PRESTACIÓN DE SERVICIOS</option>
                <option value="EMPRENDEDOR">EMPRENDEDOR</option>
                <option value="INDEPENDIENTE">INDEPENDIENTE</option>
              </select>
            </label>
            <br />
            <label>
              <select name="howDidYouHearAboutUs" value={formData.howDidYouHearAboutUs} onChange={handleChange} required>
                <option value="">CÓMO SE ENTERÓ DE NOSOTROS?</option>
                <option value="POR UN AMIGO">UN AMIGO</option>
                <option value="POR REDES SOCIALES">REDES SOCIALES</option>
                <option value="POR INTERNET">INTERNET</option>
                <option value="POR UN CLIENTE DE ANTECH">UN CLIENTE DE ANTECH</option>
              </select>
            </label>
            <label className="checkbox-container">
              <input
                type="checkbox"
                name="acceptDataTreatment"
                checked={formData.acceptDataTreatment}
                onChange={handleChange}
                required
              />
              <span className="checkmark"></span>
              Acepto el tratamiento de datos personales y consulta/reporte de información a terceros de acuerdo con la Ley 1581 de 2012 "Ley de Protección de datos personales" y Ley de Habeas Data. Declaro ser mayor de edad.
            </label>
            <br />
            <div className="send-button-container">
              <button className="send-button" type="submit">
                Enviar
              </button>
            </div>
          </form>
      )}
      </div>
    </div>
  );
};

export default FormComponent;
