import './Image.scss';

const Image = ({image}) => {
  return (
    <div className='image_block'>
      <img src={image.url} alt={image.alt}/>
    </div>
  );
};

export default Image;
