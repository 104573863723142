import React from 'react';
import styles from './Cards.module.scss';
import './Cards.scss';

const Cards = ({data}) => {
  const [flippedIndexes, setFlippedIndexes] = React.useState([]);

  const handleCardClick = index => {
    setFlippedIndexes(prevIndexes =>
      prevIndexes.includes(index)
        ? prevIndexes.filter(i => i !== index)
        : [...prevIndexes, index]
    );
  };

  return (
    <div id="credit" className="filpCard">
      <h2 className='flipCard_main-title'>{data.title}<span className='flipCard_subtitle'>{data.subtitle}</span></h2>
      <h4 className='flipCard_disclaimer'>
        {data.disclaimer}
      </h4>
      <div className={styles.grid}>
        {data.cards.map((card, index) => (
          <div key={index} className={`front-face-card ${styles.card}`} onClick={() => handleCardClick(index)}>
            <div className={`${styles.cardInner} ${flippedIndexes.includes(index) ? styles['is-flipped'] : ''}`.trim()}>
              <div className={`${styles.cardFace} ${styles.cardFront}`.trim()}>
                <img className="flipCard_image" src={card.icon} alt={card.title} />
                <p className='flipCard_title'>{card.title}</p>
              </div>
              <div className={`${styles.cardFace} ${styles.cardBack}`.trim()}>
                {card.back}
              </div>
            </div>
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default Cards;

// {Array.from({ length: 8 }, (_, index) => (
//   <div key={index} className={styles.card} onClick={() => handleCardClick(index)}>
//     <div className={`${styles.cardInner} ${flippedIndexes.includes(index) ? styles['is-flipped'] : ''}`.trim()}>
//       <div className={`${styles.cardFace} ${styles.cardFront}`.trim()}>
//         {data.cards} {index + 1}
//       </div>
//       <div className={`${styles.cardFace} ${styles.cardBack}`.trim()}>
//         This is the back!
//       </div>
//     </div>
//   </div>
// ))}

// <div>
//   <h1>{data.title}</h1>
//   <h2>{data.subtitle}</h2>
//   <div>
//     {data.cards.map((card, index) => (
//       <div key={index}>
//         <img src={card.icon} alt={card.title} />
//         <p>{card.title}</p>
//       </div>
//     ))}
//   </div>
// </div>