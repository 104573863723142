import React, { useState } from 'react';
import './HomeWidget.scss';

const HomeWidget = ({ logoHome }) => {

  return (
    <div className="home_widget">
      <a href="https://creditechantares.com">
      <img alt="Logo Home" src={logoHome} className="home_logo"/>
        </a>
    </div>
  );
};

export default HomeWidget;
