import React, { useState, useEffect } from 'react';
import './Hero.scss'; // Import CSS file for styles

const Hero = ({ heroImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        setIsTransitioning(false);
      }, 250); // Decreased transition time to minimize flickering
    }, 5000);

    return () => clearInterval(interval);
  }, [heroImages.length]);

  return (
    <div className="hero">
      <picture>
        {/* For desktop */}
        <source
          className={`hero-main-image ${isTransitioning ? 'fade-out' : 'fade-in'}`}
          media="(min-width: 601px)"
          srcSet={heroImages[currentIndex].desktop}
        />
        {/* For mobile */}
        <source
          className={`hero-main-image ${isTransitioning ? 'fade-out' : 'fade-in'}`}
          media="(max-width: 600px)"
          srcSet={heroImages[currentIndex].mobile}
        />
        {/* Fallback image for older browsers or if the sources fail to load */}
        <img
          className={`hero-main-image ${isTransitioning ? 'fade-out' : 'fade-in'}`}
          src={heroImages[currentIndex].desktop}
          alt="Responsive Image"
        />
      </picture>
    </div>
  );
};

export default Hero;
